/////////////////////////////////
// STICKY CUSTOMER FOOTER MENU //
/////////////////////////////////

.sticky-footer-menu-wrapper {
    position: fixed;
    width: 100%;
    bottom: 0px;
    display: flex;
    justify-content: center;
    z-index: 1000;
    transition: margin-left 0.25s ease;
    margin-left: 50px;

    @media (max-width: 1199px) {
        margin-left: 0px;
    }
}

.sidebar-open .sticky-footer-menu-wrapper {
    @media (min-width: 1200px) {
        margin-left: 200px;
    }
}

.sidebar-closed .sticky-footer-menu-wrapper {
    @media (min-width: 1200px) {
        margin-left: 50px;
    }
}

.footer-menu-list {
    list-style: none;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.footer-menu-button {
    padding: 5px;
    height: 100%;
    width: 125px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: 1px solid $border-color;
    background: #f5f5f5;
    position: relative;
    z-index: 5;

    @media (max-width: 1199px) {
        border-radius: 0px !important;

        .footer-menu-icon {
            font-size: 20px;
        }
    }

    @media (max-width: 767px) {
        .footer-menu-icon {
            font-size: 17px;
        }

        small {
            display: none;
        }
    }

    p {
        text-transform: uppercase;
        letter-spacing: 0.25pt;
        font-size: 12px;
        font-weight: 400;
        margin: 0;
        padding: 2px 0 0 0;
    }

    &:first-child {
        border-left: 1px solid $border-color;
        border-right: 1px solid $border-color;
        border-top-left-radius: 7px;
    }

    &:last-child {
        border-top-right-radius: 7px;
    }

    &:hover {
        cursor: pointer;
        font-weight: 700;
        background: #f0f0f0;

        .fa {
            color: $brand-red;
        }
    }

    &:active {
        background: #e9e9e9;
        transition: background 0.25s ease, box-shadow 0.125s ease;
        box-shadow: inset 0px 0px 0.25rem 0.25rem rgba(0, 0, 0, 0.05);
    }
}

.footer-menu-icon {
    transition: all 0.25s ease;
    margin-top: 7px;
    margin-bottom: 3px;
    color: #555;

    &.left-1 + p {
        position: relative;
        left: 1px;
    }
}

.sticky-footer-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    padding-bottom: 5px;
    bottom: -85px;
    height: 85px;
    border-top: 2px solid $brand-red;
    width: auto;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    transition: all 0.5s cubic-bezier(0.65, -0.35, 0.35, 1.4);
    background: #f5f5f5;

    @media (max-width: 1199px) {
        height: auto;
        width: 100%;
        border-radius: 0px;
    }

    &:hover {
        &.footer-menu-pinned-closed {
            bottom: -5px !important;
        }

        .footer-menu-settings-wrapper {
            margin-left: -44px;

            @media (max-width: 1199px) {
                margin-left: unset;
                bottom: 60px;
                opacity: 1;
            }

            @media (max-width: 767px) {
                margin-left: -10px;
                bottom: 47px;
            }
        }
    }

    &.show {
        bottom: -5px;
    }

    &.footer-menu-pinned-open {
        bottom: -5px !important;

        .footer-menu-setting.footer-menu-pin {
            transition: transform 0.2s ease, color 0.2s ease,
                border-color 0.2s ease;
            transform: rotate(66deg);
            color: #339933;
        }
    }

    &.footer-menu-pinned-closed {
        bottom: -67px !important;
        transition-delay: 0.35s;

        .footer-menu-setting.footer-menu-pin {
            transition: transform 0.2s ease, color 0.2s ease,
                border-color 0.2s ease;
            transform: rotate(-66deg);
            color: $yellow;
        }
    }

    &.footer-menu-shadow {
        box-shadow: 0px -1px 0.3rem 0.25rem rgba(0, 0, 0, 0.05);
    }

    .footer-menu-settings-wrapper {
        display: flex;
        transition: all 0.5s cubic-bezier(0.65, -0.35, 0.35, 1.4);
        transition-delay: 0.5s;
        position: absolute;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 0;
        height: 100%;
        width: 45px;

        @media (max-width: 1199px) {
            bottom: 0px;
            width: 97px;
            flex-direction: row;
            opacity: 0;
        }
    }

    .footer-menu-setting {
        transition: all 0.25s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        color: #555;
        background: #f9f9f9;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        box-shadow: 1px 0px 0.2rem 0.2rem rgba(0, 0, 0, 0.025);
        margin: 3px 0;
        border: 1px solid #ced4da;
        border-radius: 50%;

        @media (max-width: 1199px) {
            margin: 0 3px;
        }

        .fa {
            font-size: 13px;
        }

        &:hover {
            color: #b31e1f;
        }
    }
}

@media (max-width: 991px) {
    .inner-sidebar-toggle {
        display: none;
    }
}

.footer-menu-config-modal ul {
    list-style: none;
}

.footer-menu-config-modal li {
    display: inline-block;
    margin: 10px;
}

.footer-menu-config-modal .footer-menu-button {
    float: left;
    padding: 5px;
    height: 85px;
    width: 125px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #ced4da;
    background: #f5f5f5;
    position: relative;
    z-index: 5;
    border-radius: 0px;
    border-top: 2px solid #b31e1f;
}

.ui-state-highlight {
    border-top: 2px solid #b31e1f !important;
    border: 1px solid #ced4da;
    height: 85px;
    width: 125px;
    background: #f5f5f5;
    // border-radius: 0px !important;
    opacity: 0.65;
    transition: border-top-right-radius 0.25s ease,
        border-top-left-radius 0.25s ease, border-radius 0.25s ease;
}

#availableMenuButtons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

#availableMenuButtons,
#selectedMenuButtons {
    margin: 0px auto;
    padding: 15px 8px 10px;
    border-radius: 6px;
    width: 100%;
    position: relative;
    border: 1px solid #ced4da;
    box-shadow: inset 0px 0px 0.125rem 0.1rem rgba(0, 0, 0, 0.03);
    transition: all 0.25s ease;
    border: 1px solid #ced4da !important;
}

#availableMenuButtons li {
    border-radius: 0px !important;
    transition: border-top-right-radius 0.25s ease,
        border-top-left-radius 0.25s ease;
}

#selectedMenuButtons li {
    margin: 12px -2px -18px -3px;
    box-shadow: 0px -1px 0.3rem 0.25rem rgba(0, 0, 0, 0.05);
}

#selectedMenuButtons li:first-child,
// #selectedMenuButtons li.ui-state-highlight:nth-child(0),
#selectedMenuButtons li.ui-state-highlight:nth-child(1),
#selectedMenuButtons li.ui-state-highlight:nth-child(2),
#selectedMenuButtons li:first-child .footer-menu-button {
    border-top-left-radius: 6px !important;
    transition: border-top-left-radius 0.25s ease, bottom 0.25s ease;
}

#selectedMenuButtons li:last-child,
#selectedMenuButtons li.ui-state-highlight:nth-last-child(0),
#selectedMenuButtons li.ui-state-highlight:nth-last-child(1),
#selectedMenuButtons li.ui-state-highlight:nth-last-child(2),
#selectedMenuButtons li:last-child .footer-menu-button {
    border-top-right-radius: 6px !important;
    transition: border-top-right-radius 0.25s ease, bottom 0.25s ease;
}

#selectedMenuButtons {
    text-align: center;
    height: 113px;
    background: #fcfae8;
}

#availableMenuButtons {
    min-height: 100px;
    background: #fcfae8;
}

.footer-menu-config-modal {
    transition: all 0.25s ease;
}

#selectedMenuButtons li {
    bottom: -100px;
    position: relative;
    transition: bottom 0.25s ease;
}

#selectedMenuButtons.ready li {
    bottom: 0px;
}
