.table th,
.table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #ced4da;
}

.table.table-borderless th,
.table.table-borderless td {
    border-top: none;
}

.slider tr:first-child td,
.slider tr:first-child th {
    border-top: none;
}

.datatable-drawer th,
.datatable-drawer td {
    border-color: #e0e0e0;
}

.table-rounded {
    border: 1px solid #ced4da;
    border-radius: 5px;
}

.table-row.hidden-button {
    button {
        opacity: 0;
        transition: opacity 0.25s ease;
    }

    &:hover,
    &:focus,
    &:active {
        button {
            opacity: 1;
            transition: opacity 0.25s ease;
        }
    }
}

th:not([scope="row"]) {
    background: #f9f9f9;
}

.table-width {
    display: block;
    text-overflow: wrap;
    overflow-wrap: break-word;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table thead th {
    white-space: nowrap;
    border-bottom: 1px solid $border-color;
}

.has-table-drawer:hover .down-arrow:after,
.has-table-drawer:hover .up-arrow:after {
    color: $brand-red;
    transition: all 0.2s ease;
}

.order-header-right .arrow,
.table-arrow {
    display: block;

    &:after {
        display: block;
        color: #000;
        content: "\f078";
        font-family: "Font Awesome 5 Free";
        font-style: normal;
        font-weight: 900;
        text-decoration: inherit;
        font-size: 14px;
        transition: all 0.2s ease;
    }

    &.up-arrow:after {
        transform: rotate(-180deg);
    }

    &:hover:after {
        color: $brand-red;
    }
}

.table-sort {
    color: #000;
    display: block;
    width: 100%;

    &:active,
    &:focus,
    &:hover {
        text-decoration: none;
    }

    &.asc,
    &.desc {
        // color: $brand-red;
    }

    &.asc:after {
        color: #000;
        content: "\f0d8";
        font-family: "Font Awesome 5 Free";
        font-style: normal;
        font-weight: 900;
        text-decoration: inherit;
        font-size: 16px;
        padding-left: 0.5em;
    }

    &.desc:after {
        color: #000;
        content: "\f0d7";
        font-family: "Font Awesome 5 Free";
        font-style: normal;
        font-weight: 900;
        text-decoration: inherit;
        font-size: 16px;
        padding-left: 0.5em;
    }
}

.table-drawer {
    display: none;
    // background-color: #f9f9f9;
    background: $light-yellow;
    border-top: 1px solid #ced4da;
    padding: 20px;

    .card {
        transition: all 0.25s ease;
    }
}

.table-small {
    font-size: 13px;

    tr {
        th:first-child,
        td:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
        td:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }

    td {
        padding: 4px 10px;
    }
}

.table-clickable:hover {
    cursor: pointer;
    th,
    td {
        background-color: $hover-color;
        transition: background-color 0.25s ease;
    }
}

div.slider {
    display: none;
}

table.dataTable tbody td.no-padding {
    padding: 0;
}

.table.no-top-border tr:first-child td {
    border-top: none;
}

.table-order-id {
    font-weight: 700;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 110px;
}

.table-email {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 225px;
}

.striped-row {
    background: #fff;
    // background: #fbfbfb;
}

.table-striped tbody tr:nth-of-type(even),
.table-striped tbody tr:nth-of-type(odd) {
    background-color: #fff;
    // background-color: #fbfbfb;
}

.table-hover tbody tr:hover {
    background-color: rgba(248, 248, 248, 0.25) !important;
    // background-color: #f9f9f9 !important;
    transition: all 0.25s ease;
}

table.dataTable {
    margin: 0 !important;
}

.table-stripe {
    background-color: #fbfbfb;
}
