.popover {
    max-width: 400px;
    width: auto;
    border-color: #ced4da;
    box-shadow: 0 0.125rem 0.75rem rgba(0, 0, 0, 0.075);
}

.popover {
    box-shadow: 0 0.25rem 0.7rem rgba(0, 0, 0, 0.13);
    border: none !important;

    &.show {
        opacity: 1;
    }

    .popover-header {
        border-bottom: none;
        background: $brand-red;
        color: $white;
        letter-spacing: 0.35px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        padding: 12px 18px;
    }

    .popover-body {
        background: $dark-gray;
        color: $white;
        letter-spacing: 0.35px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        padding: 15px 18px;
    }

    .arrow:before {
        box-shadow: $box-shadow-lg;
        z-index: 2;
    }

    &.bs-popover-bottom .arrow:before {
        border-bottom-color: $brand-red;
        bottom: -2px;
    }

    &.bs-popover-top .arrow:before {
        border-top-color: $dark-gray;
        top: -1px;
    }

    &.bs-popover-left .arrow:before {
        border-left-color: $dark-gray;
        left: -1px;
    }

    &.bs-popover-right .arrow:before {
        border-right-color: $dark-gray;
        right: -1px;
    }
}

.tooltip {
    &.show {
        opacity: 1;
    }

    .tooltip-inner {
        width: 100%;
        max-width: 300px;
        background: $dark-gray !important;
        background-color: $dark-gray !important;
        color: $white;
        padding: 4px 10px;
        letter-spacing: 0.5px;
        box-shadow: 0 0.25rem 0.7rem rgba(0, 0, 0, 0.13);
        border: 1px solid rgba(0, 0, 0, 0.125);
        font-size: 12px !important;
    }

    .arrow:before {
        box-shadow: $box-shadow-lg;
        z-index: 2;
    }

    &.bs-tooltip-bottom .arrow:before {
        border-bottom-color: $dark-gray;
        bottom: -1px;
    }

    &.bs-tooltip-top .arrow:before {
        border-top-color: $dark-gray;
        top: -1px;
    }

    &.bs-tooltip-left .arrow:before {
        border-left-color: $dark-gray;
        left: -1px;
    }

    &.bs-tooltip-right .arrow:before {
        border-right-color: $dark-gray;
        right: -1px;
    }
}
