.has-table-drawer,
.order-drawer-card {
    .card-header:hover .arrow:after {
        color: $brand-red;
    }

    .order-header-right,
    .order-header-left {
        height: 40px;

        .small,
        small {
            line-height: 12px;
            font-weight: 400;
            font-size: 12px;
        }
    }

    .order-date,
    .order-id {
        top: 1px;
        position: relative;
    }
}

.order-header-icon {
    font-size: 16px;
    padding-top: 1px;
    padding-bottom: 0px;
    padding-left: 2px;
}

.order-costs-drawer {
    display: none;

    .highlight-td {
        border-top: 1px solid #ced4da;
        border-left: 1px solid #ced4da;
        background: #fbfbfb;

        @media (max-width: 991px) {
            &:first-child {
                border-left: none;
            }
        }
    }

    .sub-td {
        background: #fff;
        border-top: 1px solid #ced4da;
        border-left: 1px solid #ced4da;

        @media (max-width: 991px) {
            &:first-child {
                border-left: none;
            }
        }
    }

    .tooltip {
        pointer-events: none;
    }

    .order-sub-row {
        font-size: 14px;

        td {
            padding: 7px;
        }

        @media (max-width: 991px) {
            td:nth-child(2) {
                border-left: none !important;
            }
        }
    }

    .order-cost-spacer {
        display: table-cell;
        border-color: transparent;

        @media (max-width: 991px) {
            display: none;
        }
    }
}

#orderActionsLink:hover i {
    color: $brand-red;
    transition: all 0.2s ease;
}

.order-actions {
    .fas,
    .far,
    .fab,
    .fa {
        font-size: 13px;
    }
}
