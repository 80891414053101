.text-upper {
    text-transform: uppercase;
}
.white {
    color: $white;
}
.green {
    color: $green;
}
.red {
    color: $red;
}
.blue {
    color: $blue;
}
.gray {
    // color: $gray;
    color: $dark-gray;
}
.black {
    // color: #000;
    color: $dark-gray;
}
.secondary {
    color: #666;
}
.light-gray {
    color: $light-gray;
}
.lightest-gray {
    color: $lightest-gray;
}
.dark-gray {
    color: $dark-gray;
}
.light-yellow-bg {
    background: $light-yellow;
    background-color: $light-yellow;
}
.white-bg {
    background: $white;
    background-color: $white;
}
.gray-bg {
    background: $gray;
}
.green-bg {
    background: $green;
}
.red-bg {
    background: $red;
}
.blue-bg {
    background: $blue;
}
.light-gray-bg {
    background: $light-gray;
}
.lightest-gray-bg {
    background: $lightest-gray;
}
.dark-gray-bg {
    background: $dark-gray;
}
.yellow-bg {
    background: #ffbf00;
}
.orange-bg {
    background: #ff9d00;
}
.slate-bg {
    background: $slate;
}
.purple-bg {
    background: purple;
}
.brown-bg {
    background: brown;
}
.orange {
    color: $orange;
}
.yellow {
    color: $yellow;
}
.purple {
    color: purple;
}
.slate {
    color: $slate;
}
.info {
    color: #385d7a;
}
.light-yellow {
    color: $light-yellow;
}
.light-yellow-important {
    color: $light-yellow !important;
}
.info-important {
    color: #385d7a !important;
}
.white-important {
    color: $white !important;
}
.yellow-important {
    color: $yellow !important;
}
.red-important {
    color: $brand-red !important;
}
.blue-important {
    color: $blue !important;
}
.green-important {
    color: $green !important;
}
.gray-important {
    color: $gray !important;
}
.slate-important {
    color: $slate !important;
}

.ticket-orange-bg {
    background: #e34f32;
    background-color: #e34f32;
}
.ticket-solved-bg {
    background: #87929d;
    background-color: #87929d;
}

.critical {
    color: $brand-red;
    font-weight: 700;
}

.warning {
    color: $orange;
    font-weight: 700;
}

.page-link:hover {
    background-color: $hover-color;
}

.far.xlg,
.fab.xlg,
.fas.xlg,
.fa.xlg {
    font-size: 44px;
}
.far.lg,
.fab.lg,
.fas.lg,
.fa.lg {
    font-size: 30px;
}
.far.md,
.fab.md,
.fas.md,
.fa.md {
    font-size: 22px;
}
.far.sm,
.fab.sm,
.fas.sm,
.fa.sm {
    font-size: 15px;
}
.far.xs,
.fab.xs,
.fas.xs,
.fa.xs {
    font-size: 13px;
}

// Perfect scrollbar media queries
@media (max-width: 767px) {
    .ps__rail-x,
    .ps__rail-y {
        display: none !important;
    }
    .scrollable {
        overflow: auto !important;
    }
}

.flex-break {
    flex-basis: 100%;
    height: 0;
}

.default-cursor {
    cursor: default !important;
}
.not-allowed-cursor {
    cursor: not-allowed !important;
}
.pointer-cursor {
    cursor: pointer !important;
}
.help-cursor,
.info-cursor {
    cursor: help !important;
}

.no-select {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -webkit-touch-callout: none;
    -ms-user-select: none;
    -khtml-user-select: none;
}

.dont-break-out {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.preloader {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $brand-red;
    position: absolute;
    padding: 0;
    top: 0px;
    height: 100%;
    width: 100%;
    max-width: 80vw;
    max-height: 57vh;
}

.debug {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    margin: 40px 0px;
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    max-height: 400px;
    background: #f5f5f5;
}

.clickable {
    &:hover,
    &:active,
    &:focus {
        cursor: pointer;
        text-decoration: none;
        transition: all 0.15s ease;
    }
}

.no-underline {
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }
}

.w-0 {
    width: 0px;
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.pointer-none {
    pointer-events: none;
}

.overflow-auto {
    overflow: auto;
}

.overflow-visible {
    overflow: visible !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.bold {
    font-weight: 700;
}

.nowrap {
    white-space: nowrap;
}

.no-shadow {
    box-shadow: none !important;
}

.border-radius-0 {
    border-radius: 0;
}

.spin-click-disabled {
    cursor: not-allowed !important;
}
