.inner-sidebar-wrapper {
    width: 300px !important;
    flex: 0 0 300px !important;
    max-width: 300px !important;
    min-width: 300px !important;
    height: 93vh;
    margin: 0px 15px;
    padding-top: 66px !important;
    background: #f5f5f5;
    border-right: 1px solid #ced4da;
    transition: opacity 0.25s ease, max-width 0.25s ease;

    &.open {
        opacity: 1;
    }

    &.closed {
        opacity: 0;
    }

    &.closed + .inner-sidebar-toggle {
        transition: width 0.25s ease, opacity 0.25s ease;
        width: 27px;
        opacity: 1;

        &:hover,
        &:active,
        &:focus {
            width: 40px;
            opacity: 1;

            .fa {
                color: $brand-red;
                font-size: 14px;
            }
        }
    }

    @media (max-width: 991px) {
        display: none;
    }
}

.has-sidebar {
    overflow: hidden;
    margin-left: 300px;
    position: relative;
    max-width: calc(100% - 300px);
    width: 100%;
    float: right;

    @media (max-width: 991px) {
        max-width: 100%;
        margin-left: 0px;
    }

    main {
        padding-top: 5px;
        padding-left: 2px;
    }
}

.filter-clear {
    position: absolute;
    right: 30px;
    top: 12px;
    cursor: pointer;
    color: #999;
    display: none;

    &:hover,
    &:active,
    &:focus {
        transition: all 0.25s ease;
        color: $dark-gray;
    }
}

.bootstrap-select .dropdown-toggle:after {
    margin-top: 0px !important;
}

.bootstrap-select:hover {
    & + .filter-clear {
        display: block;
    }
}

#innerSidebar {
    width: 300px;
    list-style: none;
    margin: 0;
    padding: 15px;
    height: 100vh;

    .filter-menu-options {
        border-bottom: 1px solid #ced4da;

        .filter-item {
            line-height: 22px;
            padding: 2px 10px 2px 1px;

            &:first-child {
                padding-top: 10px;
            }

            &:last-child {
                padding-bottom: 17px;
            }

            label {
                color: $gray;
                font-size: 14px !important;
            }
        }
    }

    .filter-menu-parent {
        padding: 7px 3px 10px;

        &:first-child {
            padding-top: 0px;
        }

        &.no-border .filter-menu-options {
            border-bottom: none;
        }

        &:hover .filter-toggle:after {
            transition: all 0.25s ease;
            color: $brand-red;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        width: 100%;
        position: relative;
    }

    span.red {
        margin-left: 3px;
    }

    .filter-toggle {
        width: 100%;
        text-align: left;
        display: block;
        background: transparent;
        border: none;
        outline: 0;
        position: relative;
        font-weight: 700;
        font-size: 15px;
        padding-left: 1px;
        padding-right: 0px;

        &:after {
            position: absolute;
            display: block;
            font-family: "Font Awesome\ 5 Free";
            font-style: normal;
            font-weight: 900;
            text-decoration: inherit;
            content: "\f078";
            right: 1px;
            top: 2px;
            transition: all 0.3s ease;
        }

        &[aria-expanded="true"]:after {
            transform: rotate(180deg);
        }
    }
}

.inner-sidebar-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f5f5;
    height: 50px;
    width: 0px;
    border: 1px solid #cdcdcd;
    position: fixed;
    bottom: 75px;
    z-index: 10;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    opacity: 0;
    transition: width 0.25s ease, opacity 0.25s ease;

    .fa {
        transition: all 0.25s ease;
        font-size: 14px;
    }

    &.show {
        width: 27px;
        opacity: 1;
    }

    &:hover,
    &:active,
    &:focus {
        width: 40px;
        opacity: 1;

        .fa {
            color: $brand-red;
            // font-size: 14px;
        }
    }
}

.has-sidebar {
    &.has-sidebar-closed {
        max-width: 100%;
        transition: all 0.25s ease;

        & > .inner-sidebar-wrapper {
            opacity: 0;
            transition: all 0.2575s ease;
        }
    }

    @media (min-width: 992px) {
        &.has-sidebar-open {
            max-width: calc(100% - 300px);
            transition: all 0.2575s ease;
        }
    }
}
