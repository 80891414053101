.modal-content form {
    margin-bottom: unset;
}

body.modal-open {
    // position: fixed;
    // overflow-y: scroll;
}

.modal-open {
    overflow: unset;
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1083px;
    }
}

.modal.modal-fullsize {
    height: 100vh !important;
    width: 100vw !important;
    max-height: 100vh !important;
    max-width: 100vw !important;
    overflow: hidden;
    .modal-dialog,
    .modal-content {
        overflow: hidden;
        max-height: 95vh !important;
        max-width: 95vw !important;
        width: 100%;
        height: 100%;
        top: unset;
    }
}

.modal-body-wrapper {
    min-height: 100px;
    overflow: hidden;
}

.modal-dialog-centered {
    justify-content: center;
}

.modal-body-ajax .modal-body {
    min-height: 70px;
    max-height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    display: block;
}

.modal-open {
    padding-right: 0 !important;

    .modal {
        padding-right: 0 !important;
    }

    .modal-dialog {
        // top: -25px;
    }

    .modal-content {
        box-shadow: $box-shadow-lg;
        border: 1px solid rgba(0, 0, 0, 0.125);
    }

    .modal-title {
        margin-bottom: 0;
        line-height: 1.6;
        font-size: 15px;
        font-weight: 700;
    }

    .modal-header {
        background: #f9f9f9;
    }

    .modal-footer {
        padding: 0.4rem 0.7rem;
        background: #f9f9f9;
    }

    .modal-body {
        position: relative;
        flex: 1 1 auto;
        padding: 1.75rem 1.1rem;
    }
}

.modal-lightbox {
    .modal-body {
        padding: 1.1rem;
        max-height: 80vh;
    }
    .modal-footer {
        display: none;
    }
    .ax-lightbox-img {
        display: block;
        width: auto;
        height: auto;
        max-width: 90vw;
        max-height: 90vh;
    }
    .modal-dialog {
        top: unset;
    }
}

.modal-open .modal {
    overflow-y: hidden;
}

.modal-fluid {
    width: auto;
    max-width: 98%;
    .modal-content {
        width: auto;
        max-width: 98%;
        height: 100%;
    }
}

.modal.modal-static .modal-dialog {
    -webkit-transform: none !important;
    transform: none !important;
}
