.customer-stat-icon {
    margin-left: -10px;
}

.sidebar-madrona .rating-letter-na {
    top: unset !important;
    font-size: 10px !important;
    position: relative !important;
}

.subscription-list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        display: flex;
        align-items: center;
        margin: 7px 3px;

        span {
            font-size: 12px;
            margin-left: 10px;
        }
    }

    &.inline li {
        margin: 0 3px;
        display: inline-block;
    }
}

.subscription-badge {
    border-radius: 2px;
    border: 1px solid #cdcdcd;

    &.sm {
        width: auto;
        height: 15px;
    }

    &.md {
        width: auto;
        height: 25px;
    }

    &.lg {
        width: auto;
        height: 40px;
    }
}

.portal-member-actions {
    text-align: center;
    margin-top: -8px !important;

    .list-inline-item {
        margin: 5px 8px;
        flex-grow: 1;
    }
}

/////////////////////////
// ORDER STATUS FILTER //
/////////////////////////

.customer-order-filter-wrapper {
    height: 51px;

    .customer-order-filter-label {
        font-size: 15px;

        .fa {
            top: 1px;
            margin-right: 13px;
            font-size: 18px;
            position: relative;
        }
    }

    .customer-order-filter {
        min-width: 220px;
        max-width: 300px;
    }
}

.card-no-results {
    min-height: 65vh;
}

////////////////////////
// CUSTOMER VIEW TABS //
////////////////////////

.customer-tabs-wrapper {
    border-bottom: 1px solid #ced4da;
    top: 35px;
    position: relative;
    margin-bottom: -15px;

    @media (max-width: 991px) {
        top: 54px;
        margin-bottom: 8px;

        #customerTabs {
            display: none;
        }

        .customer-actions-bootstrap-select {
            width: 100% !important;
        }

        .customer-actions-wrapper {
            transition: all 0.25s ease;
            padding: 15px;
            display: block;
            width: 100%;
        }
    }

    .nav {
        box-shadow: inset -2px 0px 0.3rem rgba(0, 0, 0, 0.125);
        background: #fff;
        z-index: 10;
        position: relative;
        right: 0px;
        z-index: 999999;
        padding: 0 10px !important;
        align-items: flex-end;
        height: 85px;
        color: #2a2a2a;
        flex-wrap: nowrap;
    }

    li {
        flex-grow: 1;
    }

    .nav-link {
        white-space: nowrap;
        padding-left: 15px;
        padding-right: 15px;
        transition: all 0.25s ease;
        color: #495057;
        display: block;
        margin: 0 5px;
        cursor: pointer;
        text-align: center;
        font-size: 14px;
        letter-spacing: 0.25pt;
        padding: 12px 10px;
        border-top: 3px solid transparent;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        max-height: 39px;
        padding-left: 15px;
        padding-right: 15px;
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        margin-bottom: -1px;
        border-top: 1px solid #ced4da;
        border-left: 1px solid #ced4da;
        border-right: 1px solid #ced4da;
        opacity: 1;
        margin-bottom: 0px;
        background: rgba(0, 0, 0, 0.0125);

        &.active {
            border-top: 3px solid #b31e1f;
            border-left: 1px solid #ced4da;
            border-right: 1px solid #ced4da;
            max-height: 52px;
            margin-bottom: -1px;
            transition: max-height 0.2s ease, background 0.2s ease,
                margin-bottom 0.2s ease, border 0.2s ease, opacity 0.2s ease,
                color 0.2s ease;
            opacity: 1;
            color: #2a2a2a;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            font-style: normal;
            background: #fdfdfd;
            box-shadow: 2px -3px 0.225rem rgba(0, 0, 0, 0.1);
            overflow-y: hidden;

            .badge {
                background: #b31e1f;
                opacity: 1;
            }

            .shadow-bold {
                transition: text-shadow 0.2s ease;
                text-shadow: 0 0 0.009px #2a2a2a, 0 0 0.009px #2a2a2a;
            }

            .fa {
                color: #b31e1f;
            }
        }

        &:hover {
            transition: max-height 0.2s ease, background 0.2s ease,
                border 0.2s ease, opacity 0.2s ease, color 0.2s ease;
            border-top: 3px solid #b31e1f;
            border-left: 1px solid #ced4da;
            border-right: 1px solid #ced4da;
            background: #fdfdfd;
            margin-bottom: -1px;
            max-height: 52px;
            opacity: 1;
            box-shadow: 2px -3px 0.225rem rgba(0, 0, 0, 0.1);
            color: #2a2a2a;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;

            .fa {
                transition: all 0.25s ease;
                color: #b31e1f;
                opacity: 1;
            }

            .badge {
                transition: background 0.25s ease;
                background: #b31e1f;
                opacity: 1;
            }

            .shadow-bold {
                transition: text-shadow 0.2s ease;
                text-shadow: 0 0 0.009px #2a2a2a, 0 0 0.009px #2a2a2a;
            }
        }

        .badge {
            background: #495057;
            position: relative;
            opacity: 0.9;
            font-size: 11px;
            padding: 0px 6px !important;
            min-height: 17px;
            min-width: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -0.225px !important;
            margin-left: 8px;
        }
    }

    .disabled {
        opacity: 0.5;
        cursor: not-allowed;

        .nav-link {
            pointer-events: none;
        }
    }

    @media (max-width: 1615px) {
        .badge {
            display: none !important;
        }
    }

    @media (max-width: 1399px) {
        .fa {
            display: none !important;
        }
    }

    @media (max-width: 1199px) {
        .shadow-bold {
            font-size: 12px;
        }

        li {
            flex-grow: unset;

            .nav-link {
                margin: 0 4px -1px;
                padding: 9px 12px;
            }
        }
    }

    @media (min-width: 992px) {
        .customer-actions-wrapper {
            opacity: 0;
            left: -5000px;
            position: absolute;
            pointer-events: none;
        }
    }

    @media (max-width: 767px) {
        top: -8px;
        margin-bottom: 0px;
    }
}

// Duplicate customers
#duplicateCustomerModal {
    max-height: 90vh;

    .modal-body-ajax .modal-body.modal-body-duplicates {
        h5 {
            font-size: 15px;
            font-weight: 700;
            margin-bottom: 0.75rem;
        }
    }
}

#syncEmailsModal {
    ul {
        color: #000;
        padding: 7px 15px 10px;
        flex-grow: 1;
        list-style: none;
        border: 1px solid #c8c8c8;
        border-radius: 5px;
        max-width: 45%;
    }

    li.title {
        width: 100%;
        text-align: center;
        padding: 5px 5px 8px;
        font-weight: 700;
        text-transform: uppercase;
    }

    li span.label {
        display: inline-block;
        font-weight: 600;
        width: 60px;
    }
}
