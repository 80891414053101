.nav-tabs .nav-link {
    color: #495057;

    .fas,
    .far,
    .fab,
    .fa {
        color: #495057;
    }
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: $dark-gray;
    background-color: #f9f9f9;
    border-color: #dee2e6 #dee2e6 #f8fafc;
    border-bottom: none !important;

    &.nav-link-white {
        background-color: #fff;
    }

    .fas,
    .far,
    .fab,
    .fa {
        color: $brand-red;
    }

    &:active,
    &:hover,
    &:focus {
        border-bottom: none !important;
    }
}

.nav-tabs {
    border-bottom: none !important;
    z-index: 5;
    position: relative;

    .nav-item {
        margin-bottom: -1px;
    }

    .badge {
        margin-right: -3px;
        top: -2px;
        position: relative;
    }

    .nav-link,
    .nav-link:hover,
    .nav-link:active,
    .nav-link:focus,
    a,
    a:hover,
    a:focus,
    a:active,
    &:hover,
    &:focus,
    &:active {
        transition: all 0.25s ease;
        border-bottom: none !important;
    }
}

.tab-content {
    transition: all 0.25s ease;
}
