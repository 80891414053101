.note-card-list-wrapper {
    // height: 250px;
    height: 215px;
    overflow-y: scroll;
    background: $light-yellow;

    .notes-empty {
        height: 100%;
    }
}

.note-card-list {
    list-style: none;

    .added-by {
        height: 24px;
    }

    .note-item {
        padding: 25px 7px;

        .note-name {
            width: 100%;
            line-height: 24px;
            position: relative;
            top: 1px;
        }

        .note {
            overflow-wrap: break-word;
            padding-top: 2px;
        }

        .circle {
            line-height: 13px;
            font-size: 13px;
        }

        &:hover .note-actions li {
            transition: all 0.2s ease;
            display: inline;
        }

        &:not(:last-child) {
            border-bottom: 1px solid #f0f0f0;
        }
    }

    .note-actions {
        transition: all 0.2s ease;
        list-style: none;
        margin: 0;
        padding: 0;
        width: 150px;
        text-align: right;

        li {
            text-align: center;
            padding: 2px 3px;
            display: none;
        }
    }

    .note-action {
        text-decoration: none;
        color: $gray;
        padding: 0;
        margin: 0;
        background: none;
        border: none;
        outline: none;

        &:hover {
            transition: all 0.2s ease;
            color: $brand-red;
        }
    }
}

// Ticket Customer Notes List
.customer-notes-list.note-card-list-tickets {
    transition: max-height 0.5s ease;
    max-height: 111px;
    overflow: visible;

    .notes-empty {
        height: 111px;

        strong {
            padding: 20px 10px;
            font-size: 13px !important;
            color: #333 !important;
        }
    }

    .note-card-list {
        max-height: 111px;
        transition: max-height 0.5s ease;
        margin: 0px !important;
        padding: 0px !important;
    }

    &.expanded {
        transition: max-height 0.5s ease;
        max-height: 222px;

        .note-card-list {
            max-height: 222px;
            transition: max-height 0.5s ease;
        }
    }

    .circle.sm {
        height: 20px;
        width: 20px;
        font-size: 11px;
    }

    .note-name {
        top: 2px;
        position: relative;
        font-size: 13px;
    }

    .note-item {
        border-radius: 5px;
        border: 1px solid $border-color !important;
        background: $light-yellow;
        padding: 0.75rem 1rem !important;
        margin-top: 1rem;
        margin-bottom: 1rem;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0.5rem;
        }
    }

    .note-actions {
        white-space: nowrap;
        width: auto !important;

        button:first-child {
            padding-right: 0px !important;
            padding-left: 0px !important;
        }

        .fa,
        .fab,
        .far,
        .fas {
            padding: 0 1px;
            font-size: 12px;
        }

        li {
            padding-left: 0px;
            padding-right: 0px;

            &:last-child {
                .fa,
                .fab,
                .far,
                .fas {
                    padding-right: 0px;
                }
            }
        }
    }
}
