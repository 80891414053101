.report-range {
    #subnavDateFilter + i {
        margin: 0;
        padding: 5px;
        border: 0;
        outline: 0;
        background: none;
        top: 6px;
        position: absolute;
        right: 8px;
    }
}

@media (max-width: 991px) {
    #subnavDateFilterForm {
        width: unset !important;
        min-width: unset !important;
        max-width: unset !important;
    }
    #subnavDateFilter {
        opacity: 0;
        visibility: hidden;
        width: 1px;

        & + i {
            font-size: 22px;
            top: 9px;

            &:hover,
            &:active,
            &:focus {
                color: $brand-red;
                transition: all 0.25s ease;
            }
        }
    }
}

.logistics-overview {
    #ordersByLocation {
        overflow: hidden;
        height: 383px;
        position: relative;
        border-right: 1px solid #ced4da;
        min-height: 383px;
    }

    #ordersByLocationChart {
        padding: 5px;
        height: 360px;
        width: 100%;
        margin-top: 5px;
    }

    .obl-filter-label {
        bottom: -1px;
    }
}

.orders-by-country-chart {
    height: 430px;
    width: 100%;
}

.orders-by-country-empty {
    height: 406px;
}

.orders-by-country-wrapper {
    position: relative;
    border-right: 1px solid #ced4da;
    height: 478px;
}

#exportButtons {
    right: 1px;
}

#subnavDateFilter:hover {
    cursor: pointer;
    border: 1px solid #ced4da;

    &:hover {
        opacity: 0.975;
        transition: all 0.2s ease;
    }

    & + .far.fa-calendar-alt {
        pointer-events: none;
    }
}

.revenue-breakdown-stats {
    border-left: 1px solid #ced4da;

    @media (max-width: 991px) {
        border-left: none;
    }
}

.flag-icon {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.125);

    &.state {
        width: 22px;
        height: 16px;
        background-repeat: no-repeat;
        background-size: cover;
        top: -1px;
        position: relative;
        background-position: center center;
    }
}

g text {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

@media (max-width: 991px) {
    .export-btn-wrapper,
    .nav-tabs .nav-link span:not(.badge) {
        display: none;
    }

    .nav-tabs .nav-link .fa {
        padding-right: 0px !important;
    }
}

@media (max-width: 1199px) {
    #shipmentsByLocation,
    #ordersByLocation {
        border-bottom: 1px solid #ced4da;
    }
}

.overview-quick-stats {
    &.index-stats {
        margin-bottom: 0;
        margin-top: 3px;

        .card {
            margin-bottom: 24px;
        }
    }

    // .stat-wrapper {
    //     width: 100%;
    //     min-height: 53px;
    //     display: block;
    // }

    .stat-wrapper {
        width: 100%;
        min-height: 40px;
        display: block;
    }

    // .stat-box-icon {
    //     width: 100px;
    //     height: 75px;
    //     display: flex;
    //     border-radius: 5px;
    //     max-height: 73px;
    //     box-shadow: 0 0.125rem 0.65rem rgba(0, 0, 0, 0.25);
    //     align-items: center;
    //     justify-content: center;

    //     .fa-check-circle,
    //     .fa-clock {
    //         font-size: 48px;
    //     }
    // }

    .stat-box-icon {
        width: 100px;
        height: 65px;
        display: flex;
        border-radius: 5px;
        max-height: 73px;
        box-shadow: 0 0.125rem 0.65rem rgba(0, 0, 0, 0.25);
        align-items: center;
        justify-content: center;

        // .fa-check-circle,
        // .fa-clock {
        //     font-size: 48px;
        // }

        .fa,
        .far,
        .fas,
        .fab {
            font-size: 2.25rem;
        }
    }

    .stat-label {
        font-weight: 700;
        color: $gray;
        white-space: nowrap;
        font-size: 15px;
    }

    .stat-percentage {
        font-weight: 700;
        font-size: 16px;
    }

    // .stat {
    //     line-height: 46px;
    //     font-weight: 700;
    //     font-size: 46px;
    // }

    .stat {
        line-height: 44px;
        font-weight: 700;
        font-size: 38px;
    }

    @media (max-width: 991px) {
        .stat-label {
            font-size: 13px;
        }

        .stat {
            line-height: 38px;
            font-size: 36px;
        }

        .stat-box-icon {
            width: 80px;

            .fas,
            .far,
            .fab,
            .fa {
                font-size: 32px;
            }
        }
    }
}

.overview-quick-stats {
    .card-footer {
        font-size: 12px;
    }
}

.anychart-credits,
.modebar-container {
    display: none !important;
}

.breakdown-stat-wrapper {
    white-space: nowrap;
    .label {
        font-size: 16px;
        padding: 0;
        margin: 0;
    }
    span {
        // font-size: 32px;
        font-size: 28px;
        margin-top: -8px;
        position: relative;
        display: block;
        font-weight: 700;
    }
    .change {
        font-size: 16px;
        top: -2px;
        position: relative;
    }
}

@media (max-width: 1199px) {
    .orders-by-location {
        tr:last-child {
            border-bottom: 1px solid #ced4da;
        }
        .orders-by-location-map {
            padding: 20px 10px;
        }
    }
}

@media (max-width: 991px) {
    .revenue-breakdown-stats {
        width: 100%;
        border-top: 1px solid #ced4da;
        .d-flex {
            padding: 12px;
            justify-content: center;
            text-align: center;
            // padding-left: 10px;
        }
        .change {
            margin: 0;
            display: block;
        }
    }
}
