.customer-autocomplete-dropdown {
    max-height: 200px;
    overflow-y: scroll;
}

.bootstrap-select.show-tick .dropdown-menu li a span.text {
    margin-right: 0;
    padding-right: 34px;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.dropdown-menu.fade {
    display: block;
    opacity: 0;
    pointer-events: none;
}

.show > .dropdown-menu.fade {
    pointer-events: auto;
    opacity: 1;
}

.dropdown-header {
    padding: 0.5rem 1.325rem;
    color: #2a2a2a;
    font-weight: 700;
}

.menu-icon {
    display: inline-flex;
    width: 20px;
    text-align: center;
    justify-content: center;
}

.dropdown-menu {
    color: $dark-gray;
    font-size: 12px;
    box-shadow: $box-shadow-lg;
    border-color: #ced4da;

    .dropdown-item {
        &.active {
            &:focus,
            &:active {
                background-color: $brand-red;
                color: $white;

                .fas,
                .far,
                .fab,
                .fa {
                    color: $white;
                }
            }
        }

        .fa,
        .fas,
        .far {
            transition: all 0.25s ease;
        }
    }

    .dropdown-item:hover {
        transition: all 0.25s ease;
        background: #f5f5f5;
        color: $dark-gray;
    }
}

#mobileDropdown:after {
    display: none;
}

.notifications-dropdown-list {
    min-width: 360px;
    max-width: 500px;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
}

.notification-dropdown-item {
    background-color: #fbfbfb;
    border-bottom: 1px solid #e9ecef;
    cursor: pointer;
}

.notification-dropdown-item:last-child {
    border-bottom: none;
}

.bs-ok-default.check-mark {
    color: $green;
}

.dropdown-item.active,
.dropdown-item:active {
    .fa,
    .far,
    .fas,
    .fab {
        color: $white;
    }
}

.dropdown-item,
.dropdown-item:hover {
    .fa,
    .far,
    .fas,
    .fab {
        color: $brand-red;
    }
}
