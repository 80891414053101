input,
select,
textarea,
label,
.bootstrap-select .filter-option-inner-inner {
    font-family: "Roboto", sans-serif;
}

.bootstrap-select .filter-option {
    margin-top: 1px;
}

.bootstrap-select .dropdown-menu {
    min-width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: sticky;
}

.bootstrap-select .dropdown-menu.inner {
    overflow: hidden;
}

.datepicker-input {
    @extend .no-select;

    cursor: pointer;
    display: flex;
    align-items: center;

    input {
        width: 100%;
        flex-grow: 1;
        padding-left: 44px;
        position: relative;
        z-index: 1;
    }

    &:before {
        border: 1px solid #777;
        border-radius: 50%;
        transition: all 0.25s ease;
        text-align: center;
        display: block;
        position: absolute;
        font-size: 12px;
        margin-left: 12px;
        margin-right: 0.5rem !important;
        height: 22.5px;
        color: #777;
        pointer-events: none;
        z-index: 2;
        content: "\f133";
        font-family: "Font Awesome\ 5 Free";
        // font-weight: 900;
        font-style: normal;
        text-decoration: inherit;
        height: 24px;
        width: 24px;
        font-size: 14px !important;
    }
}

// Add placeholder support to contenteditable containers.
[contenteditable]:not(.tagify__input):empty:before {
    content: attr(placeholder);
    color: $dark-gray;
    display: inline-flex;
    align-items: center;
}

.readonly-normal {
    @extend .form-control;
    background: $white !important;
    cursor: pointer !important;
}

.has-icon-right {
    position: relative;

    input {
        padding-right: 30px;
    }

    input + i {
        pointer-events: none;
        margin: 0;
        padding: 5px;
        border: 0;
        outline: 0;
        background: none;
        float: right;
        top: 7px;
        position: absolute;
        right: 6px;
    }
}

.has-icon-left {
    position: relative;

    input {
        padding-left: 30px;
    }

    input + i {
        pointer-events: none;
        margin: 0;
        padding: 5px;
        border: 0;
        outline: 0;
        background: none;
        float: left;
        top: 7px;
        position: absolute;
        left: 6px;
    }
}

.hover-input {
    @extend .form-control;
    background: transparent;
    border: 1px solid transparent;
    display: block;
    outline: none;
    width: 100%;
    box-shadow: none;
    height: auto;
    color: $dark-gray;
    transition: all 0.3s ease;

    &.bold {
        font-weight: 700;
    }

    &.title {
        font-size: 1.125rem;
    }

    &:hover {
        background: $white;
        color: $dark-gray;
        border-color: #ced4da;
    }

    &:active,
    &:focus {
        background: $white;
        border-color: #e66a6b;
        color: $dark-gray;
    }
}

.filter-option {
    display: flex;
    align-items: center;
}

select:disabled,
.bootstrap-select.disabled {
    background-color: #e9ecef !important;
    opacity: 1 !important;
    cursor: not-allowed;
}

select,
select[multiple] {
    opacity: 0;
    position: absolute;
    height: 37.02px !important;
    display: block;
}

@media (max-width: 767px) {
    .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(
            .input-group-btn
        ) {
        width: 100%;
    }
}

.filter-option-inner-inner {
    color: $gray;
}

.bootstrap-select .dropdown-toggle {
    height: 37.02px;
}

.dropdown.select-hover-open .dropdown-menu {
    margin-top: 3px;
}

.dropdown.no-caret {
    .dropdown-toggle:after {
        display: none;
    }
}

.select .dropdown-toggle {
    background: #fff;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    color: $dark-gray;
    line-height: 14px;
    padding: 0.658rem 0.75rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:active,
    &:focus {
        color: #495057;
        background: #fff !important;
        background-color: #fff !important;
        border-color: #e66a6b !important;
        outline: 0 !important;
        box-shadow: 0 0 0 0.2rem rgba(179, 30, 31, 0.25) !important;
    }
}

.bs-searchbox {
    margin-bottom: 8px;
}

.select:focus,
.select:active,
.select.show {
    border-color: #e66a6b;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(179, 30, 31, 0.25) !important;
    border-radius: 5px;
}

.select.show .dropdown-toggle {
    color: #495057;
    background: #fff !important;
    background-color: #fff !important;
    border-color: #e66a6b !important;
    outline: 0 !important;
    box-shadow: $box-shadow;
}

.select-pics {
    .dropdown-toggle {
        padding-top: unset;
        padding-bottom: unset;
    }

    .dropdown-menu li a {
        display: flex;
        align-items: center;
        padding: 10px 0.835rem;
    }

    .filter-option-inner-inner {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .name {
        top: 1px;
        position: relative;
    }

    .select-pic {
        display: flex;
        align-items: center;

        .circle,
        img {
            @extend .circle;
            @extend .mr-2;
            @extend .circle-border;
            height: 24px;
            width: 24px;
            position: relative;
            top: 0;
            font-size: 14px !important;
        }
    }
}

.bootstrap-select .dropdown-menu li a.opt {
    padding-left: 1.475rem;
}

.bootstrap-select.with-counts {
    .dropdown-toggle {
        padding-top: 1px;
        padding-bottom: 0px;
        display: flex;
        align-items: center;
    }

    .filter-option,
    .filter-option-inner,
    .filter-option-inner-inner {
        width: 100%;
        overflow: visible;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 0px;

        .badge-count {
            display: none;
        }
    }

    span.text {
        width: 100%;
        display: flex !important;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .badge-count {
        @extend .badge;
        @extend .badge-pill;
        @extend .badge-light;
        font-size: 11px;
        margin-right: -6px;
    }

    .dropdown-item {
        &.active,
        &:active,
        &:focus,
        &:hover {
            .badge-count {
                transition: all 0.25s ease;
            }
        }

        .fa,
        .fas,
        .far {
            display: none;
        }
    }
}

.daterangepicker select.minuteselect,
.daterangepicker select.hourselect,
.daterangepicker select.ampmselect {
    @extend .form-control;
    @extend .select;
    display: inline-block;
    visibility: visible;
    cursor: pointer;
    width: 70px;
    background: #f5f5f5;
    color: $gray;
    font-size: 13px;
    transition: all 0.25s ease;
    height: 30px !important;
}

.daterangepicker .calendar-time {
    margin: 4px auto 0 -6px;
}

.daterangepicker .drp-buttons .btn {
    @extend .btn;
    @extend .btn-sm;

    &.applyBtn {
        @extend .btn-primary;
    }

    &.cancelBtn {
        @extend .btn-secondary;
    }
}

span.text {
    width: auto;
}

.select .dropdown-toggle {
    padding-top: unset;
    padding-bottom: unset;
}

.hidden-input:focus,
.hidden-input:active,
.hidden-input:hover {
    @extend .form-control;
    transition: all 0.25s !important;
}

.hidden-input {
    height: 37px !important;
    display: flex !important;
    align-items: center !important;
    border: 1px solid transparent;
    line-height: 37px !important;
    padding-left: 5px !important;
    font-size: 1.35rem !important;
    font-weight: 500 !important;
    transition: all 0.25s !important;

    &:before {
        opacity: 0.4;
        pointer-events: none;
        transition: opacity 0.25s;
    }
}

.clearable-input {
    display: flex;
    align-items: center;
    position: relative;

    .clear-input {
        background: #555;
        border-radius: 50%;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 11px;
        line-height: 11px;
        height: 16px;
        width: 16px;
        position: absolute;
        z-index: 999;
        right: 10px;
        cursor: pointer;
        opacity: 0;
        font-weight: 600;
        pointer-events: none;
        transition: opacity 0.25s ease 0.1s, background 0.25s ease 0s;
    }

    &:hover {
        .clear-input {
            opacity: 1;
            pointer-events: initial;

            &:hover {
                background: $brand-red;
            }

            &:active {
                background: #555;
            }
        }
    }
}

// input[type="file"] {
//     display: none;
// }
