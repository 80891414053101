.btn:not([role="combobox"]):not(.btn-link):not(.btn-transparent) {
    letter-spacing: 0.25px;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2) !important;

    &:focus {
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2) !important;
    }

    &:active {
        transition: all 0.25s ease;
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.025) !important;
    }
}

.btn-transparent-icon {
    box-shadow: none !important;
    outline: none;
    border: none;
    background: transparent;
    background-color: transparent;
    border-color: transparent;
}

.btn-secondary {
    background-color: $dark-gray;
    border-color: $dark-gray;

    &:hover,
    &:active,
    &:focus {
        background-color: #555 !important;
        border-color: #555 !important;
    }
}

.btn-outline-secondary {
    border-color: $dark-gray;
    color: $dark-gray;

    &:active {
        color: $white;
        background-color: #4a4a4a !important;
        border-color: #4a4a4a !important;
    }

    &:focus,
    &:hover {
        color: $white;
        background-color: $dark-gray;
        border-color: $dark-gray;
    }
}

.btn.btn-outline-secondary,
.btn.btn-outline-primary,
.btn.btn-outline-warning,
.btn.btn-outline-info {
    box-shadow: none !important;
}

button,
button:active,
button:focus,
button:hover,
a,
a:focus,
a:active,
a:hover {
    transition: all 0.2s ease;
    text-decoration: none;
    outline: none !important;
}

.btn {
    font-size: 11px;
}

button:disabled {
    cursor: not-allowed;
}

.icon-button {
    display: block;
    min-width: 135px;
    width: 100%;

    .fa {
        font-size: 22px;
        padding-top: 10px;
        color: $brand-red;
        transition: all 0.25s ease;
    }

    small {
        font-size: 10px;
        text-transform: uppercase;
        letter-spacing: 0.35pt;
        color: $dark-gray;
        transition: all 0.25s ease;
    }

    &:hover {
        text-decoration: none;
        transition: all 0.25s ease;

        .fa {
            color: $dark-gray;
        }
    }
}

#backToTop {
    position: fixed;
    display: none;
    bottom: 20px;
    right: 20px;
    height: 40px;
    width: 40px;
    z-index: 9999;

    @media (max-width: 1199px) {
        bottom: 80px;
        right: 10px;
    }

    @media (max-width: 767px) {
        bottom: 53px;
    }
}

.back-to-top {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $brand-red;
    color: #fff;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    cursor: pointer;

    &:hover {
        opacity: 0.9;
    }

    .fa-chevron-up {
        font-size: 20px;
    }
}
