aside.sidebar {
    background-color: $brand-gray;
    height: calc(100vh - #{$navbar-height});
    width: $sidebar-width;
    display: flex;
    position: fixed;
    -webkit-box-shadow: 4px 0 0.25rem rgba(0, 0, 0, 0.125);
    -moz-box-shadow: 4px 0 0.25rem rgba(0, 0, 0, 0.125);
    box-shadow: 4px 0 0.25rem rgba(0, 0, 0, 0.125);
    left: 0px;
    transition: none;
    z-index: 123;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 767px) {
        left: -500px;
    }

    .sidebar-menu-icons {
        list-style: none;
        padding: 0;
        margin: 0;
        text-align: center;
        transition: all 0.25s ease;
        bottom: 0px;
        width: 100%;
        position: relative;

        .sidebar-hover-info {
            overflow: hidden;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            opacity: 0;
            transition: all 0.25s ease;
            position: absolute;
            left: 100px;
            background: #2a2a2a;
            color: #fff;
            font-size: 13px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 0px;
            max-width: 0px;
            padding: 0px;
            font-weight: 700;
            z-index: 95;
            white-space: nowrap;
            margin-top: -48px;
            box-shadow: 2px 2px 0.25rem rgba(0, 0, 0, 0.2);
        }

        .sidebar-module {
            color: #fff;
            transition: all 0.3s ease;

            .sidebar-module-icon {
                transition: all 0.3s ease;
                border: 1px solid transparent;
                color: #b8b8b8;
            }

            &.opening,
            &.open {
                .sidebar-module-icon {
                    background: #333;
                    border: 1px solid rgba(0, 0, 0, 0.35);
                    color: #fff;
                    opacity: 1 !important;

                    &:hover {
                        color: #fff;
                    }
                }
            }

            &.closing,
            &.closed {
                .sidebar-module-icon {
                    &:hover {
                        border: 1px solid rgba(0, 0, 0, 0.35);
                        background: #3a3a3a;
                        color: #fff;
                    }
                }
            }

            .fa,
            .fas,
            .far,
            .fab {
                padding-top: 5px;
                padding-bottom: 7px;
            }

            p {
                font-size: 10px;
            }
        }

        .sidebar-module-icon {
            background: transparent;
            transition: all 0.3s ease;
            padding: 6px 2px;
            border-radius: 3px;
            width: 72px;
            margin: auto;

            &:hover,
            &:active,
            &:focus {
                background: #3a3a3a;
            }
        }

        .sidebar-submenu-list {
            border-radius: 2px;
            list-style: none;
            transition: all 0.2s ease;
            width: 100%;
            padding: 0px 0px 10px;
            position: relative;
            z-index: 99;

            .fa,
            .fas,
            .far,
            .fab {
                font-size: 24px;
                color: #b8b8b8;
                padding: 12px 0;
            }

            p {
                display: none;
            }

            .badge-pill {
                transition: all 0.3s ease;
                padding-right: 0.4em;
                padding-left: 0.4em;
            }

            li {
                height: 48px;
            }
        }

        &.footer a {
            margin: 10px 0 -3px;
        }

        small {
            font-size: 9px;
        }

        a {
            letter-spacing: 0.25pt;
            display: block;
            color: #fff;
            position: relative;
            text-decoration: none;

            .fa,
            .far,
            .fas,
            .fab {
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                transition: color 0.25s ease;
            }

            &.active {
                color: #fff;

                .fa,
                .fas,
                .far,
                .fab {
                    color: #fff;
                }
            }

            &:hover,
            &:active {
                transition: 0.25s ease;
                color: #fff;
                background: $brand-red;

                .badge-pill {
                    background: $orange;
                    color: #2a2a2a;
                }

                .sidebar-hover-info {
                    transition-delay: 0.1s;
                    background: $brand-red;
                    opacity: 1;
                    width: 150px;
                    max-width: 150px;
                    z-index: 50;
                }

                .fa,
                .fas,
                .far,
                .fab {
                    color: #fff;
                }
            }
        }
    }
}

.sidebar-menu-icons.footer {
    transition: all 0.5s cubic-bezier(0.65, -0.35, 0.35, 1.4) !important;

    .fa,
    .fas,
    .fab,
    .far {
        color: #b8b8b8;
    }

    a.active,
    a:focus,
    a:active,
    a:hover {
        color: #fff;
        background: transparent !important;
    }
}

body.footer-open {
    .sidebar-menu-icons.footer {
        @media (max-width: 1191px) {
            bottom: 65px;
            position: relative;
        }
    }
}
