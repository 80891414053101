$asana-green: #00bf9c;

.clickable-card {
    transition: box-shadow .25s ease, border .25s ease, transform .2s ease;

    &:hover {
        border: 1px solid #ccc;
        box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.15);
        transform: translateY(-1px);
    }

    &:active {
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.07);
    }
}

// TODO: Turn on attachments button once wired up.
.issue-tracker.issue-tracker-view {
    .copyright {
        display: none;
    }
    trix-toolbar .trix-button-group:not(:first-child) {
        border: 0;
    }
    trix-toolbar .trix-button-group:not(:first-child) {
        margin-left: 0px;
    }

    [data-trix-button-group="file-tools"],
    .trix-button--icon-attach,
    .trix-button--icon-heading-1,
    .trix-button--icon-quote,
    .trix-button--icon-code {
        display: none;
    }
}

.issue-tracker .sticky-footer-menu-wrapper,
.issue-tracker-view #backToTop {
    display: none !important;
}

.issue-tracker .bootstrap-select .dropdown-toggle .filter-option-inner-inner {
    overflow: visible;
}

.issue-tracker.issue-tracker-view main .container-fluid {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    height: 100%;
    min-height: 86vh;
}












.issue-tracker-index {
    .ui-sortable-helper:active,
    .draggable-card:active {
        z-index: 999999;
        cursor: dragging !important;
    }
    .project-section {
        position: relative;
        z-index: 1;
    }
    .no-transitions ul.project-section li:not(.ui-sortable-helper):not(.draggable-card-placeholder),
    .no-transitions .card,
    .no-transitions .card-draggable {
        transition: none !important;
    }
    .draggable-card-placeholder {
        max-height: 0px;
        min-height: 0px;
        margin-bottom: 17px;
        height: 100%;
        transition: max-height .25s, min-height .25s;
    }
    .no-transitions .draggable-card-placeholder {
        min-height: 90px;
        transition: max-height .25s;
        border-radius: 3px;
        background: #f5f5f5;
    }
    .card.issue ~ .empty.card,
    .card.issue ~ .empty.card,
    .project-section ~ .empty.card,
    .project-section + .empty.card,
    .draggable-card-placeholder ~ .empty.card,
    .draggable-card-placeholder + .empty.card {
        position: absolute !important;
        display: none !important;
        overflow: hidden !important;
        opacity: 0;
    }
    .ps--active-x .ps__rail-x,
    .ps--active-y .ps__rail-y {
        opacity: 0.6;
    }
    .issues-board-wrapper {
        overflow: auto;
        position: relative;
        width: 100%;
        height: 100%;
    }
    .issue-board-column + .ps__rail-x .ps__thumb-x {
        background-color: #aaa;
        border-radius: 5px;
        transition: background-color 0.2s linear, height 0.2s ease-in-out;
        -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
        height: 10px;
        bottom: 3px;
        position: absolute;
    }
    .ps__rail-y .ps__thumb-y {
        border-radius: 5px;
        width: 10px;
        right: 0px;
    }
    #issuesBoard {
        overflow: auto !important;
        position: relative;
    }
}






















.issue-tracker.issue-tracker-view,
.issue-tracker.issue-tracker-index {
    main {
        min-height: 87vh !important;
        bottom: 0;
    }

    .container-fluid {
        bottom: 0;
        margin: 0 -1px;
        padding: 0;
    }

    .copyright {
        opacity: 0;
        padding: 0;
        margin: 0;
        height: 0;
        z-index: 0;
    }

    .ax-main-content {
        height: 100% !important;
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
    }
}

.issues-board-wrapper {
    display: flex;
    margin: 10px 0;
    padding: 10px 15px 0;
    margin-bottom: 10px !important;
    z-index: 2;
    width: 100%;
    height: calc(100vh - 140px);
    position: relative;
    overflow-x: auto;
}

.issue-stats {
    font-size: 12px;
    bottom: 0;
    right: 0;
    color: #666;

    .fa, .far, .fas, .fab {
        margin-left: 3px;
        position: relative;
        top: -1px;
    }

    .issue-likes {
        .fa {
            font-size: 11px;
            line-height: 11px;
        }

        &.liked {
            color: $blue;
        }
    }

    .issue-subtasks {
        .fa {
            font-size: 10px;
            line-height: 11px;
        }
    }
}

.issue-board-column {
    overflow: hidden;
    border-radius: 3px;
    min-width: 320px;
    width: 320px;
    max-width: 320px;
    flex-grow: 1;
    padding: 0 10px;
    position: relative;
    margin-left: -5px;

    &:last-child {
        margin-right: 0;
    }

    h5 {
        padding-left: 5px;
        font-size: 16px;
        max-width: 270px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .empty {
        padding-top: 2px;
        border-radius: 3px;
        box-shadow: none;
        height: calc(100vh - 250px);
        border: 1px solid #c8c8c8;
        background: #f9f9f9;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .card.issue {
        @extend .clickable-card;

        min-height: 80px;
        margin-bottom: 15px;
        position: relative;

        &.dropdown-open {
            z-index: 9;
        }

        &:last-child {
            margin-bottom: 15px;
        }

        &.dropdown-open .issue-dropdown,
        &:hover .issue-dropdown {
            display: block;
        }
    }

    .issue-dates {
        top: 1px;
        position: relative;
        font-size: 12px;

        .issue-due-today {
            color: $asana-green;
        }
    }

    .card.issue-completed {
        .issue-title,
        .issue-dates,
        .issue-avatar-wrapper,
        .badge-issue-priority {
            opacity: .5;
            transition: all .25s ease;
        }

        .issue-dates,
        .issue-due-today {
            color: $gray;
        }

        .issue-title h6 {
            width: 195px;
            font-weight: 300;
        }
    }

    .card.last {
        width: 200px;
    }

    .card-body.no-issues {
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        color: #999;
    }
}

.issue-complete-circle {
    min-height: 18px;
    min-width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    font-size: 10px;
    margin: 0;
    padding: 0;
    background: $asana-green;
    align-self: flex-start;
}

.asana-green-bg {
    background: $asana-green;
}

.asana-green {
    color: $asana-green;
}

.issue-board-column-scroll {
    height: calc(100vh - 203px);
    margin-bottom: 0;
    padding-bottom: 0;
    padding: 2px 5px 0;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    z-index: 8;
}

.badge-issue-priority {
    color: #fff !important;
    letter-spacing: .25pt;
    font-size: 11px;
    line-height: 11px;
    padding: 4px 10px;

    &.yellow-orange {
        background: #fd9a00;
    }

    &.red {
        background: #e8384f;
    }

    &.orange {
        background: #fd612c;
    }
}

.issue-tooltip {
    text-align: left;

    strong {
        margin-bottom: -2px;
        position: relative;
        display: block;
    }
}

.issue-dropdown [data-toggle="dropdown"] {
    border-radius: 50%;
    transition: background .25s ease, color .25s ease;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #222;
    align-self: flex-start;

    &:hover,
    &:active {
        color: $brand-red;
    }
}

.issue-header {
    min-height: 20px;
}

.issue-dropdown {
    height: 20px;
    width: 20px;
    display: flex;
    align-self: flex-start;
    display: none;
}

.issue-circle-wrapper,
.issue-avatar-wrapper {
    &.small .issue-circle,
    &.sm .issue-circle,
    &.small .issue-avatar,
    &.sm .issue-avatar {
        height: 27px;
        width: 27px;
        font-size: 12px;
        line-height: 12px;
    }

    &.medium .issue-circle,
    &.md .issue-circle,
    &.medium .issue-avatar,
    &.md .issue-avatar {
        height: 32px;
        width: 32px;
        font-size: 13px;
        line-height: 14px;
    }

    &.large .issue-circle,
    &.lg .issue-circle,
    &.large .issue-avatar,
    &.lg .issue-avatar {
        height: 36px;
        width: 36px;
        font-size: 14px;
        line-height: 14px;
    }

    .issue-circle,
    .issue-avatar {
        border-radius: 50%;
        border: 1px solid #ced4da;
    }

    .issue-circle-color,
    .issue-avatar-initials {
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;

        &.purple-bg {
            background: #7a6ff0;
        }

        &.white-bg {
            background: $white;
            color: #222;
            border: 1px solid #666;
        }
    }
}

.draggable-card {
    cursor: grab;
}

.draggable-card:active {
    cursor: grabbing !important;
}

.due-on {
    &.due-on-overdue {
        color: $brand-red;
    }

    &.due-tomorrow,
    &.due-today {
        color: $asana-green;
    }
}

.subtask-completed .due-on,
.issue-completed .due-on {
    color: initial;
}

.subtask-item {
    border-bottom: 1px solid $border-color-light;
    width: 100%;
    display: block;
    cursor: pointer;
    transition: background .25s ease;
    display: inline-flex;
    align-items: center;
    font-size: 13px;
    justify-content: space-between;

    .subtask-name {
        position: relative;
        top: 1px;
        padding: 10px 2px;
        width: 100%;
        height: 100%;
    }

    a {
        flex-grow: 1;
        display: block;
        color: #222;

        &:focus,
        &:active
        &:hover {
            color: #222;
        }
    }

    &:first-child {
        border-top: 1px solid $border-color-light;
    }

    .subtask-actions {
        white-space: nowrap;
    }

    .subtask-icon {
        align-items: center;
        justify-content: center;
        display: flex;
        font-size: 11px;
        line-height: 11px;
        width: 22px;
        height: 22px;
        min-width: 22px;
        min-height: 22px;
        max-width: 22px;
        max-height: 22px;
        text-align: center;
        border-radius: 50%;
        border: 1px solid $border-color;
    }

    &.subtask-completed {
        .subtask-actions,
        .subtask-name {
            opacity: .35;
        }
    }

    .incomplete-icon {
        color: $border-color;
        background: $white;
        transition: all .25s ease;

        &:hover {
            border-color: $asana-green;
            color: $asana-green;
        }
    }

    .complete-icon {
        border: 1px solid $asana-green;
        background: $asana-green;
        color: $white !important;
        transition: all .25s ease;
        opacity: 1;

        &:hover {
            opacity: .75;
        }
    }
}

#issueAttachments {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.issue-sidebar-label {
    font-size: 13px;
    color: #666;
    margin-bottom: 1px;
}

.issue-attachments {
    li {
        height: 66px;
        margin: 8px 16px 8px 0;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.issue-attachment-wrapper {
    position: relative;
    height: 66px;

    &:hover .issue-attachment-remove {
        transform: translateY(-1px);
        pointer-events: initial;
        opacity: 1;
        transition: .35s ease 0s;
        animation: .35s ease 0s zoomIn;
    }

    .issue-attachment-remove {
        @extend .no-select;

        display: flex;
        pointer-events: none;
        z-index: 9;
        align-items: center;
        justify-content: center;
        position: absolute;
        font-size: 13px;
        cursor: pointer;
        top: -8px;
        right: -11px;
        padding: 2px 5px 5px 5px;
        opacity: 0;
        transition: opacity .2s ease 0s, transform .2s ease 0s;

        i {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background: $brand-red;
            color: $white;
        }

        &:hover + .issue-attachment,
        &:active + .issue-attachment {
            transform: translateY(-1px);
        }

        &:active {
            transform: translateY(0px);
            transition: transform .15s ease 0s;
        }
    }
}


.issue-attachment {
    @extend .clickable-card, .no-select;

    height: 100%;
    margin: 0;
    width: auto;
    max-width: 300px;
    border-radius: 6px;
    border: 1px solid $border-color;
    background: $white;
    color: #222;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    position: relative;
    box-shadow: $box-shadow;
    color: #222;

    &:not(.issue-attachment-image) {
        padding: 15px 20px;
    }

    img {
        height: 75px;
        width: auto;
    }

    a {
        color: #222;
    }

    .attachment-icon {
        margin-right: 10px;
        width: 38px;
        height: 38px;
        border-radius: 5px;
        background: $gray;
        color: $white;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        align-self: center;
    }

    &.pdf .attachment-icon {
        background: $brand-red;
    }

    &.jpg .attachment-icon,
    &.jpeg .attachment-icon,
    &.png .attachment-icon,
    &.gif .attachment-icon {
        background: $green;
    }

    &.mpg .attachment-icon,
    &.mp4 .attachment-icon,
    &.mpeg .attachment-icon,
    &.mov .attachment-icon {
        background: $yellow;
    }

    &.txt .attachment-icon,
    &.doc .attachment-icon,
    &.md .attachment-icon,
    &.xls .attachment-icon,
    &.docx .attachment-icon {
        background: $purple;
    }

    &.zip .attachment-icon,
    &.rar .attachment-icon {
        background: $dark-gray;
    }

    .attachment-name,
    .attachment-info {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
    }

    .attachment-download {
        color: $gray;
        padding: 5px 0;
    }

    .attachment-info .attachment-download:hover {
        color: $brand-red;
    }

    .attachment-name {
        padding-right: 3px;
        color: #222;
        font-size: 13px;
    }

    .attachment-info {
        margin: -3px 0 1px;
        color: $gray;
        font-size: 11px;
    }

    &:not(.png):not(.jpg):not(.jpeg):not(.gif) {
        padding: 10px 15px;
    }
}

.column-top {
    border-radius: 10px;
    z-index: 9;
    z-index: 99;
    height: 15px;
    width: 300px;
    pointer-events: none;
    position: absolute;
    box-shadow: inset 0px 10px 10px -10px rgba(0, 0, 0, 0.0);
    transition: box-shadow .175s ease;

    &.shadow-top {
        box-shadow: inset 0px 10px 10px -10px rgba(0, 0, 0, 0.2)
    }
}


////////////////////
// ISSUE COMMENTS //
////////////////////

.issue-comments {
    margin-top: 5px;
    background: #fbfbfb;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    flex-grow: 1;
    padding-bottom: 150px;

    .issue-comments-list {
        li:first-child .issue-comment-inner {
            padding-top: 0px;
        }
    }

    .pinned-comment {
        background: $light-yellow;
        box-shadow: inset 3px 0 $yellow;
    }

    .comment-divider {
        border-color: #e8ecee;
        border-style: solid none none;
        border-width: 1px;
        margin: 0;
    }

    .issue-comment-avatar {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width: 55px;
        min-width: 55px;
    }

    .issue-comment-inner {
        padding: 12px 0;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

    }

    .issue-comment-body-wrapper {
        flex-grow: 1;
        width: 100%;
    }

    .issue-comment-header {
        padding-top: 6px;
        display: flex;
        align-items: baseline;
    }

    .issue-comment-creator-name {
        font-weight: 700;
        margin-right: 10px;
        font-size: 15px;
        line-height: 15px;
    }

    .issue-comment-created-at {
        line-height: 15px;
        white-space: nowrap;
    }

    .issue-comment-body .issue-attachment {
        max-width: 440px;
    }

    .issue-comment-body .issue-attachment .attachment-name,
    .issue-comment-body .issue-attachment .attachment-info {
        max-width: 360px;
    }

    .issue-comment-event,
    .issue-comment-event-body {
        display: flex;
    }
}


////////////////////////
// ISSUE COMMENT FORM //
////////////////////////

.issue-tracker-view {
    background: #fff;
    background-color: #fff;
}

.issue-comment-form {
    display: flex;
    border-top: 1px solid #e8ecee;
    min-height: 150px;
    width: 100%;
    bottom: 0px;
    padding-top: 25px;
    padding-bottom: 25px;
    background: #fbfbfb;
    box-shadow: none;
    transition: background .75s ease, box-shadow .35s ease;
    overflow-x: hidden;
    overflow-y: visible;
    margin-left: -1px;
    right: 0;
    max-height: 30vh;

    @media(max-width: 1199px) {
        padding-bottom: 25px;
    }

    @media(max-width: 991px) {
        max-width: calc(100% - #{$sidebar-width}) !important;
    }

    @media(max-width: 767px) {
        max-width: 100% !important;
    }

    &.shadow-top {
        background: #f5f5f5;
        box-shadow: 0px 5px 0.525rem 0.25rem rgba(0, 0, 0, 0.1125);
    }

    .trix-wrapper {
        @extend .form-control;
        z-index: 9;
        overflow: hidden;
        position: relative;
        height: 100%;
        padding: 0px;
        flex-grow: 1;
        box-shadow: none;
        transition: max-width 0.29s ease, background .75s ease, box-shadow .2s ease, padding .35s ease;
    }

    trix-editor {
        padding: 12px 15px;
        height: 100%;
        flex-grow: 1;
        border: none;
        outline: none;
        box-shadow: none;
        overflow-y: auto;
        transition: max-height .25s linear, min-height .25s linear, padding .25s linear;
        background: #fff;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 82px;

        &[contenteditable]:empty:before {
            color: #6c757d !important;
            pointer-events: none;
        }

        .attachment {
            @extend .issue-attachment;
        }

        .attachment__caption {
            .attachment__name {
                @extend .attachment-name;
            }
            .attachment__size {
                @extend .attachment-info;
            }
        }
    }

    trix-toolbar {
        transition: transform .25s ease;
        transform: translateY(50px);
        white-space: nowrap;
        bottom: 6px;
        margin-left: 6px;
        background: transparent;
        overflow: hidden;
        min-height: 40px;
        max-height: 40px;
        position: absolute;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: calc(100% - 20px);

        .trix-input--dialog {
            border: 1px solid #ced4da;
            font-size: 13px;
            font-family: 'Roboto';
        }

        .trix-button--dialog {
            @extend .btn, .btn-secondary;
            outline: none !important;
            background: #2a2a2a;
            background-color: #2a2a2a;
            border-color: #2a2a2a;
            color: #fff;
            margin-right: 5px;

            &:focus,
            &:active,
            &:hover {
                background-color: #555 !important;
                border-color: #555 !important;
                color: #fff !important;
                letter-spacing: .25px;
                box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2) !important;
                transition: all 0.2s ease;
            }

            &:active {
                box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.025) !important;
                transition: all 0.25s ease;
            }

            &:focus {
                box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2) !important;
            }
        }

        .trix-button-group,
        button.trix-button {
            height: 100%;
            outline: 0;
            box-shadow: none;
            background: transparent;
            border: 2px solid #fff;
        }

        .trix-button:hover {
            color: #000;
        }

        .trix-button-group {
            margin-bottom: 0;

            &:not(:first-child) {
                margin-left: 0px;
            }
        }

        button.trix-button.trix-active {
            filter: none;
            opacity: 1;
            border-radius: 5px;
            border: 2px solid #fff;
            background: #f8f9fa;
            box-shadow: inset 0 0 3px #ced4da;
        }

        .trix-dialogs {
            background: transparent;
            left: 0;
            bottom: 0;
            max-width: 750px;
            width: calc(100% - 200px);
            position: absolute !important;

             .trix-dialog {
                display: block;
                top: unset;
                left: unset;
                right: unset;
                pointer-events: none;
                border-radius: 4px;
                position: absolute;
                background: transparent;
                border: none;
                width: 100%;
                margin: 0;
                min-width: 100%;
                padding: 0;
                margin-left: 5px;
                box-shadow: none;
                transform: translateY(10px);
                transition: transform .35s ease .15s;

                &.trix-active {
                    pointer-events: initial;
                    transform: translateY(-38px);
                    transition: transform .35s ease .15s;
                    width: 100%;
                    background: #fff;
                }
            }
        }
    }

    .trix-focus {
        padding: 0 0 42px 0;
        color: #495057;
        background-color: #fff;
        border-color: #e66a6b;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(179, 30, 31, 0.25) !important;

        trix-toolbar {
            transform: translateY(0px);
        }

        trix-editor {
            max-height: 25vh;
        }
    }

    .issue-comment-editor {
        position: relative;
    }

    .issue-comment-form-avatar {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width: 55px;
        min-width: 55px;
    }
}

.issue-comment-form,
.has-sidebar-open .issue-comment-form {
    width: 100%;
    transition: max-width 0.29s ease, background .75s ease, box-shadow .2s ease, padding .35s ease;
    max-width: calc(100% - 300px - #{$sidebar-width});
}

.has-sidebar-closed .issue-comment-form {
    width: 100%;
    transition: max-width .21s ease, background .75s ease, box-shadow .2s ease, padding .35s ease;
    max-width: calc(100% - #{$sidebar-width});
}

.issue-status-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0px;
    margin-top: 0px;

    &.xs {
        border-radius: 3px;
        padding: 2px 8px;
        font-size: 10px;
        line-height: 10px;
    }

    &.sm {
        border-radius: 3px;
        padding: 4px 10px;
        font-size: 11px;
        line-height: 11px;
    }

    &.md {
        border-radius: 4px;
        padding: 4px 10px;
        font-size: 12px;
        line-height: 12px;
    }

    &.lg {
        border-radius: 5px;
        padding: 5px 15px;
        font-size: 13px;
        line-height: 13px;
    }

    &.issue-completed {
        color: $white !important;
        background: $asana-green !important;
        border-color: $asana-green !important;
    }
}

.issue-status-circle {
    .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        border-radius: 50%;
    }

    &.issue-completed .circle {
        color: $white !important;
        background: $asana-green !important;
    }
}

.issue-avatar-unassigned {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 22px !important;
    line-height: 22px !important;
    color: #6f7782 !important;
    background: #fff !important;
    background-color: #fff !important;
    border: 1px dashed #6f7782 !important;
    font-size: 14px !important;
    line-height: 14px !important;
    cursor: pointer;
}

.issue-board-column .card.issue.card-draggable .issue-avatar-wrapper,
.issue-board-column .card.issue.card-draggable.issue-completed .issue-avatar-wrapper,
.issue-avatar-wrapper {
    transition: background .25s, border .25s, border-style .25s, opacity .25s;

}
.issue-board-column .card.issue.card-draggable.issue-completed .issue-avatar-wrapper:hover,
.issue-avatar-wrapper:hover {
    cursor: pointer;
    opacity: 1 !important;
}

.issue-board-column .card.issue.draggable-card:active,
.issue-board-column .card.issue.draggable-card:active span,
.issue-board-column .card.issue.draggable-card:active div {
    cursor: grabbing !important;
}

.issue-board-column .card.issue-completed .issue-avatar-unassigned:hover,
.issue-board-column .card .issue-avatar-unassigned:hover,
.issue-avatar-wrapper.issue-avatar-unassigned:hover {
    background: #fbfbfb;
    border-style: solid !important;
    border-color: #ccc;
}

.select-pics .issue-avatar-unassigned {
    .fa, .far, .fas, .fab {
        color: #6f7782 !important;
    }
}

.issue-board-column .card.issue.card-draggable .issue-avatar-unassigned {
    opacity: 0 !important;
}
.issue-board-column .card.issue.card-draggable:hover .issue-avatar-unassigned {
    opacity: 1 !important;
}

.issue-board-column .card.card-draggable .ui-sortable-handler.ui-sortable-helper:active,
.issue-board-column .card.card-draggable .ui-sortable-handler.ui-sortable-helper {
    cursor: dragging !important;
}


#issueNotesEditor trix-editor + div {
    @extend .form-control;
}



.notes-editor-wrapper .ql-toolbar.notes-toolbar-sticky,
.notes-editor trix-toolbar.notes-toolbar-sticky {
    transform: translateY(60px);
}

.notes-editor-wrapper.notes-editor-focus .ql-toolbar.notes-toolbar-sticky,
.notes-editor.trix-focus trix-toolbar.notes-toolbar-sticky {
    transform: translateY(0px);
}

.notes-editor-wrapper .ql-toolbar.notes-toolbar-sticky,
.notes-editor trix-toolbar.notes-toolbar-sticky {
    right: 0;
    padding: 10px 30px;
}

.notes-editor-wrapper .ql-toolbar.notes-toolbar-sticky,
.has-sidebar-open .notes-editor-wrapper .ql-toolbar.notes-toolbar-sticky,
.notes-editor trix-toolbar.notes-toolbar-sticky,
.has-sidebar-open .notes-editor trix-toolbar.notes-toolbar-sticky {
    width: 100%;
    transition: max-width 0.29s ease, box-shadow .2s ease, padding .35s ease, transform .35s ease .2s;
    max-width: calc(100% - 300px - #{$sidebar-width});
}

.has-sidebar-closed .notes-editor-wrapper .ql-toolbar.notes-toolbar-sticky,
.has-sidebar-closed .notes-editor trix-toolbar.notes-toolbar-sticky {
    width: 100%;
    transition: max-width .21s ease, box-shadow .2s ease, padding .35s ease, transform .35s ease .2s;
    max-width: calc(100% - #{$sidebar-width});
}

.notes-editor-wrapper .ql-toolbar.notes-toolbar-sticky,
.notes-editor trix-toolbar.notes-toolbar-sticky {
    max-width: 100%;

    @media(max-width: 991px) {
        max-width: calc(100% - #{$sidebar-width}) !important;
    }

    @media(max-width: 767px) {
        max-width: 100% !important;
    }
}











.issue-tracker-index {
    .ui-sortable-helper:active,
    .draggable-card:active {
        z-index: 999999;
        cursor: dragging !important;
    }
    .project-section {
        position: relative;
        z-index: 1;
    }
    .no-transitions ul.project-section li:not(.ui-sortable-helper):not(.draggable-card-placeholder),
    .no-transitions .card,
    .no-transitions .card-draggable {
        transition: none !important;
    }
    .draggable-card-placeholder {
        max-height: 0px;
        min-height: 0px;
        margin-bottom: 17px;
        height: 100% !important;
        transition: max-height .25s, min-height .25s;
    }
    .no-transitions .draggable-card-placeholder {
        min-height: 90px;
        transition: max-height .25s;
        border-radius: 3px;
        background: #f5f5f5;
    }
    .card.issue ~ .empty.card,
    .card.issue ~ .empty.card,
    .project-section ~ .empty.card,
    .project-section + .empty.card,
    .draggable-card-placeholder ~ .empty.card,
    .draggable-card-placeholder + .empty.card {
        position: absolute !important;
        display: none !important;
        overflow: hidden !important;
        opacity: 0;
    }
    .ps--active-x .ps__rail-x,
    .ps--active-y .ps__rail-y {
        opacity: 0.6;
    }
    .issues-board-wrapper {
        overflow: auto;
        position: relative;
        width: 100%;
        height: 100%;
    }
    .issue-board-column + .ps__rail-x .ps__thumb-x {
        background-color: #aaa;
        border-radius: 5px;
        transition: background-color 0.2s linear, height 0.2s ease-in-out;
        -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
        height: 10px;
        bottom: 3px;
        position: absolute;
    }
    .ps__rail-y .ps__thumb-y {
        border-radius: 5px;
        width: 10px;
        right: 0px;
    }
    #issuesBoard {
        overflow: auto !important;
        position: relative;
    }

    @media(max-width: 767px) {
        .issue-tracker-board {
            // height: calc(100vh - 120px);
        }
        .copyright {
            display: none;
        }
    }
}













.notes-editor {
    .trix-input--dialog {
        border: 1px solid #ced4da;
        font-size: 13px;
        font-family: 'Roboto';
    }

    .trix-button.trix-button--dialog {
        @extend .btn, .btn-secondary;
        outline: none !important;
        background: #2a2a2a !important;
        background-color: #2a2a2a !important;
        border-color: #2a2a2a !important;
        color: #fff !important;
        margin-right: 5px;
        border: 1px solid #2a2a2a !important;

        &:focus,
        &:active,
        &:hover {
            background-color: #555 !important;
            border-color: #555 !important;
            color: #fff !important;
            letter-spacing: .25px;
            box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2) !important;
            transition: all 0.2s ease;
        }

        &:active {
            box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.025) !important;
            transition: all 0.25s ease;
        }

        &:focus {
            box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2) !important;
        }
    }
    .trix-dialogs {
        background: transparent;
        left: 0;
        bottom: 0;
        max-width: 750px;
        width: calc(100% - 200px);
        position: absolute !important;

         .trix-dialog {
            display: block;
            top: unset;
            left: unset;
            right: unset;
            pointer-events: none;
            border-radius: 4px;
            position: absolute;
            background: transparent;
            border: none;
            width: 100%;
            margin: 0;
            min-width: 100%;
            padding: 0;
            margin-left: 5px;
            box-shadow: none;
            transform: translateY(10px);
            transition: transform .35s ease .15s;

            &.trix-active {
                pointer-events: initial;
                transform: translateY(-38px);
                transition: transform .35s ease .15s;
                width: 100%;
                background: #fff;
            }
        }
    }
}











.issue-tracker {
    .hidden-input[contenteditable][name="name"] span,
    .hidden-input[contenteditable][name="name"] div,
    .hidden-input[contenteditable][name="name"] a,
    .hidden-input[contenteditable][name="name"] a,
    .hidden-input[contenteditable][name="name"] strong,
    .hidden-input[contenteditable][name="name"] em,
    .hidden-input[contenteditable][name="name"] i,
    .hidden-input[contenteditable][name="name"] u,
    .hidden-input[contenteditable][name="name"] {
        height: 37px !important;
        color: #2a2a2a !important;
        display: flex !important;
        align-items: center !important;
        border: 1px solid transparent;
        line-height: 37px !important;
        padding-left: 5px !important;
        font-size: 1.35rem !important;
        font-weight: 500 !important;
        transition: all .25s !important;
        background: transparent !important;
        background-color: transparent !important;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
    }
    .hidden-input[contenteditable][name="name"] b {
        display: none !important;
    }
}











.comments-editor-wrapper .ql-tooltip,
.notes-editor-wrapper .ql-tooltip {
    display: flex;
    height: 45px;
    margin-left: 6px;
    &:before,
    &:after {
        line-height: normal;
        display: flex;
        align-items: center;
    }
}

.comments-editor-wrapper .ql-tooltip .ql-preview,
.comments-editor-wrapper .ql-tooltip input[type="text"],
.notes-editor-wrapper .ql-tooltip .ql-preview,
.notes-editor-wrapper .ql-tooltip input[type="text"] {
    @extend .form-control;
    display: inline-block;
    position: relative;

    &:active,
    &:focus {
        border-color: #ced4da !important;
        box-shadow: none !important;
        outline: 0 !important;
    }
}
.comments-editor-wrapper a.ql-action,
.notes-editor-wrapper a.ql-action {
    @extend .btn, .btn-secondary;
}
.comments-editor-wrapper a.ql-remove,
.notes-editor-wrapper a.ql-remove {
    @extend .btn, .btn-danger;
}
.comments-editor-wrapper a.ql-action,
.comments-editor-wrapper a.ql-remove,
.notes-editor-wrapper a.ql-action,
.notes-editor-wrapper a.ql-remove {
    display: inline-block;
    line-height: normal !important;
    margin-left: 8px;
    margin-top: 1px;
    height: 31px;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    min-width: 65px;
    &:before,
    &:after {
        display: flex;
        align-items: center;
        border: none !important;
        padding: 0 !important;
        margin: 0 !important;
    }
}
