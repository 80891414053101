.ticket-viewers-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.popover-ticket-viewers-list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        padding-bottom: 5px;
    }

    li:last-child {
        padding-bottom: 0px;
    }
}

.ticket-tag-input.disabled:active,
.ticket-tag-input.disabled:focus,
.ticket-tag-input.disabled:hover,
.ticket-tag-input.disabled {
    box-shadow: none !important;
    cursor: not-allowed;
    outline: 0;
}

#ticketModal .ticket-viewers-list {
    list-style: none;
    margin: 0;
    padding: 0;
    cursor: default;
    transition: all 0.25s ease;
    max-width: 30%;
    display: flex;
    height: 24px;
    width: 0px;
    justify-content: space-evenly;

    li:not(:first-child) {
        width: 24px;
        height: 24px;
        position: relative;
        color: #fff;
        font-weight: 700;
        font-size: 14px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 0 0 0 10px;
        background: #2a2a2a;
        line-height: 13px;
        opacity: 1;
        transition: opacity 0.25s ease, width 0.25s ease;

        &:hover,
        &:active,
        &:focus {
            transition: opacity 0.25s ease;
            opacity: 0.75;
        }
    }

    li:first-child {
        position: relative;
        font-size: 12px;
        color: #2a2a2a;
        line-height: 11px;
        opacity: 1;
        width: 45px;
        transition: opacity 0.25s ease, width 0.25s ease;
    }

    li:last-child {
        // margin-right: 0;
    }
}

.ticket-viewers-icon {
    // ...
}

.ck-link_selected {
    cursor: pointer;
}

.ck.ck-input.ck-input-text,
.ck.ck-link-actions .ck-button.ck-link-actions__preview .ck-button__label,
.ck.ck-link-actions .ck-button.ck-link-actions__preview {
    max-width: 345px !important;
    min-width: 345px;
    width: 100%;
}

.ck.ck-content a {
    cursor: pointer;
}

#ticketPreloader .preloader {
    height: 100%;
    width: 100%;
    background: #fff;
    background-color: #fff;
    z-index: 999999;
}

#suggestionsDropdown {
    max-width: 400px;
}

.followup-icon {
    margin-left: -4px;
}

.badge-status {
    font-weight: normal;
    position: relative;
    color: $white;
    font-size: 11px;

    &.badge-status-new {
        background: rgb(255, 182, 72);
    }

    &.badge-status-open {
        background: rgb(227, 79, 50);
    }

    &.badge-status-pending {
        background: rgb(48, 145, 236);
    }

    &.badge-status-closed,
    &.badge-status-solved {
        background: rgb(135, 146, 157);
    }
}

.badge-status-closed-icon {
    border-radius: 50% !important;
}

.badge-priority {
    color: $white;
    min-width: 80px;
    padding: 4px 8px !important;
    // box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    font-size: 10px !important;
    letter-spacing: 0.35pt;

    &.badge-priority-low {
        background: $light-gray;
    }

    &.badge-priority-normal {
        background: $gray;
    }

    &.badge-priority-high {
        background: $orange;
    }

    &.badge-priority-urgent {
        background: $brand-red;
    }
}

#submitTicket {
    border-right: 1px solid rgba(248, 248, 248, 0.75);
}

#ticketSubmitAs {
    border-left: 1px solid rgba(248, 248, 248, 0.75);

    &:after {
        display: none;
    }
}

.square {
    margin-right: 3px;
    height: 10px;
    width: 10px;
    display: inline-block;

    &.square-new {
        background: rgb(255, 182, 72);
    }
    &.square-pending {
        background: rgb(48, 145, 236);
    }
    &.square-solved {
        background: rgb(135, 146, 157);
    }
    &.square-open {
        background: rgb(227, 79, 50);
    }
}

.ticket-history,
.ticket-form {
    .tagify__tag {
        margin: 4px 0 5px 5px !important;
    }

    .tagify__tag > div::before {
        border-radius: 15px !important;
    }

    .tagify__tag-text {
        font-size: 12px;
        margin-top: 3px;
    }

    .tagify__tag__removeBtn {
        margin-top: 2px;
        margin-right: 7px;
        &:after {
            margin-top: 1px;
        }
    }

    .tagify__tag[readonly] > div:before {
        background: #f5f5f5;
    }

    .ck.ck-editor__main > .ck-editor__editable.ck-focused,
    .ck.ck-editor__main > .ck-editor__editable {
        background: transparent !important;
        background-color: transparent !important;
    }

    .ck-editor__editable_inline {
        min-height: 300px;
    }
    .ck.ck-editor_editable {
        color: #495057 !important;
    }
    .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
        border: 1px solid #ced4da !important;
        border-bottom: none !important;
    }
    .ck.ck-editor__main > .ck-editor__editable {
        border: 1px solid #ced4da !important;
        border-bottom: none !important;
    }
    .ck-editor__main,
    .ck-editor__top {
        border-color: #ced4da !important;
        margin-bottom: 45px;
    }
    .ck-button {
        cursor: pointer !important;
    }
    .ck.ck-list__item .ck-button.ck-on {
        background: #b31e1f !important;
    }
    .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
    .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
        @extend .shadow;
        width: 100%;
        bottom: -39px;
        position: absolute;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border: 1px solid #ced4da !important;
        border-top: none !important;
    }
    .ck-content.ck-editor__editable {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .ck-content {
        border-radius: 5px !important;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        padding-bottom: 5px !important;
    }
    .to-tag-input {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-bottom: none;
        box-shadow: none !important;
        border-color: #ced4da !important;
        margin: 1px !important;
    }
    .ck.ck-dropdown__panel,
    .ck.ck-dropdown__panel .ck-list {
        border-radius: 3px !important;
        outline: none !important;
        box-shadow: 0 0.25rem 0.425rem rgba(0, 0, 0, 0.065) !important;
        border: none !important;
    }
    .ck.ck-dropdown__panel {
        margin-top: 2px !important;
        border: 1px solid #ced4da !important;
    }
    .ck.ck-list__item button:hover {
        background: #f5f5f5 !important;
    }
    .ck.ck-list__item button {
        outline: none !important;
        border: none !important;
    }
    .ck.ck-list__item:first-child,
    .ck.ck-list__item:first-child .ck-button.ck-on {
        border-top-left-radius: 3px !important;
        border-top-right-radius: 3px !important;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }
    .ck.ck-list__item:last-child,
    .ck.ck-list__item:last-child .ck-button.ck-on {
        border-bottom-left-radius: 3px !important;
        border-bottom-right-radius: 3px !important;
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }
    .ck-focused {
        color: #495057 !important;
        background-color: #fff !important;
        border-color: #ced4da !important;
        outline: 0 !important;
        z-index: 0;
        box-shadow: none !important;
    }
    .ck-editor {
        @extend .shadow;
        background: #fff !important;

        p {
            margin: 0px !important;
        }

        .ck-editor__editable {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
    .as-internal .ck-editor__editable {
        border: 1px solid #ced4da !important;
        border-top-left-radius: 5px !important;
        border-top-right-radius: 5px !important;
    }
    .ck.ck-editor .ck-editor__top .ck-sticky-panel .ck-toolbar {
        @extend .shadow;
        z-index: 10 !important;
        border-top: 1px solid #ced4da !important;
    }
    .ck.ck-toolbar__separator {
        background: #ced4da !important;
    }
    .to-wrapper {
        margin-left: 0px;
        width: 100%;
        height: 50px;
        padding: 0.375rem 0.75rem;
        font-size: 0.9rem;
        font-weight: 400;
        line-height: 1.6;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-bottom: none;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        .to-label {
            width: 20px;
            font-size: 14px;
            line-height: 1.4;
        }
    }

    .tag-no-edit {
        pointer-events: none;
    }

    .tagify__input {
        color: #495057;
        line-height: 1.4;
        font-size: 14px;
        margin: 6px 5px 5px;
        padding: 0.235em 0.5em;

        &:after {
            color: #495057;
        }

        &:before {
            display: none;
        }

        &:focus:before,
        &:active:before {
            display: block;
        }
    }
    .ticket-form-tabs .nav-link:focus,
    .ticket-form-tabs .nav-link:active,
    .ticket-form-tabs .nav-link:hover,
    .ticket-form-tabs .nav-link {
        font-weight: 400;
        letter-spacing: 0.2pt;
        border-left: none !important;
        border-right: none !important;
        border-top: none !important;
        background: transparent !important;
        padding: 3px 3px !important;
        color: #777 !important;
        margin-right: 25px !important;
        border-bottom: 3px solid transparent !important;
    }
    .ticket-form-tabs .nav-link.active:active,
    .ticket-form-tabs .nav-link.active:focus,
    .ticket-form-tabs .nav-link.active:hover,
    .ticket-form-tabs .nav-link.active {
        color: $dark-gray !important;
        border-left: none !important;
        border-right: none !important;
        border-top: none !important;
        background: transparent !important;
        border-bottom: 3px solid #b31e1f !important;
    }

    .ck.ck-editor__main > .ck-editor__editable {
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }
}

.ticket-form-footer {
    z-index: 10;
    left: 20px;
    max-width: 100vw;
    background: #f9f9f9;
    border-top: 1px solid #ced4da;
    margin-left: -15px;
    bottom: 0px;
    padding-top: 10px;
    padding-bottom: 10px;

    .ticket-footer-button {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        font-size: 13px;
        padding: 7px 25px;
    }

    .dropdown-menu {
        z-index: 2;
        position: relative;
        font-size: 13px;
        color: #cdcdcd;
    }
}

#ticketEditor {
    visibility: hidden;
}

.ticket-closed-label {
    font-weight: 500;
    font-size: 12px;
}

.ticket-user-table {
    font-size: 12px;

    .fa,
    .fas,
    .fab,
    .far {
        color: #555;
        font-size: 11px;
    }

    .fa-external-link-alt {
        color: #555;
        top: -1px;
        position: relative;

        &:active,
        &:focus,
        &:hover {
            color: $brand-red;
        }
    }

    .signature-icon {
        margin-left: 2px;
    }

    input,
    textarea {
        font-size: 12px;
        width: 100%;
    }
}

.sidebar-filter-buttons-wrapper.full-width {
    padding: 0 15px;
}

.ticket-sidebar {
    .agent-name {
        top: 1px;
        position: relative;

        h5 {
            font-size: 14px;
            font-weight: 500;
        }
    }
}

.ticket-sidebar-main {
    .filter-menu-options.full-width {
        padding: 0 18px;

        &.with-badges {
            padding: 0 16px 0 18px;
        }
    }

    .filter-item a {
        font-size: 14px !important;
        color: #555;
    }
    .filter-item a .badge {
        background: transparent;
        color: $dark-gray;
        position: relative;
        border: 1px solid #cdcdcd;
    }
    .filter-item a.active .badge {
        color: #fff;
        background: #b31e1f;
        border-color: #b31e1f;
    }
    .filter-item a.active:before {
        display: block;
        color: #b31e1f;
        content: "\f105";
        font-family: "Font Awesome 5 Free";
        font-style: normal;
        font-weight: 900;
        text-decoration: inherit;
        font-size: 14px;
        transition: all 0.2s ease;

        padding-right: 5px;
        display: inline-block;
        position: relative;
    }
    .filter-item a:hover {
        color: #b31e1f;
        transition: all 0.2s ease;
    }
}

.ticket-sidebar-main,
.ticket-sidebar-view {
    .customer-order-item:hover,
    .interaction-item:hover {
        background: #efefef;
    }
    .customer-order.active,
    .interaction-item.active {
        background: #efefef;
    }

    .filter-menu-options:not(.full-width),
    .filter-menu-parent button:first-child,
    .filter-menu-parent button:first-child:after {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .filter-menu-parent.full-width {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .filter-menu-parent.full-width button:first-child,
    .filter-menu-parent.full-width button:first-child:after {
        padding-left: 18px !important;
        padding-right: 18px !important;
    }

    .filter-menu-parent:not(:last-child) .filter-menu-options {
        border-bottom: none !important;

        &:after {
            border-bottom: 1px solid #ced4da;
            display: block;
            position: relative;
            margin: 0 auto;
            padding: 0 3px;
            content: "";
        }

        &.full-width:after {
            border-bottom: none;
        }
    }

    .customer-order-item {
        position: relative;
    }
    .customer-order-item,
    .interaction-item {
        padding-top: 10px;
        padding-bottom: 5px;
        padding-left: 25px;
        padding-right: 15px;
    }
    .interaction-item:before,
    .customer-order-item:before {
        content: "";
        background: #ced4da;
        position: absolute;
        display: block;
        height: 1px;
        width: 270px;
        padding-left: 0px;
        padding-right: 0px;
        margin-left: -10px;
        margin-top: -10px;
    }
    .customer-order-item:nth-child(1):before,
    .interaction-item:first-child:before {
        display: none !important;
    }

    .end-user-table {
        font-size: 12px;
    }

    .end-user-name {
        top: 1px;
        position: relative;

        h5 {
            font-size: 14px;
            font-weight: 500;
        }
    }

    .portal-badge {
        .far,
        .fa,
        .fas,
        .fab {
            color: $white;
        }
        letter-spacing: 0.5px;
        font-size: 14px;
    }

    .note {
        font-size: 13px;
    }

    .customer-order-wrapper {
        margin-bottom: -5px;

        .order-date {
            top: -4px;
        }

        .order-id {
            font-size: 13px;
            font-weight: 500;
        }

        .order-total {
            top: -7px;
            position: relative;
        }
    }

    .interaction-wrapper {
        .ticket-subject {
            font-size: 13px;
            font-weight: 500;
        }

        .ticket-date {
            top: -4px;
        }
    }
}

.ticket-status-column {
    max-width: 20px !important;
    padding: 0px !important;
}

.ticket-popover-wrapper {
    max-width: 100%;

    .ticket-status {
        position: relative;
        top: -2px;
    }
}

.ticket-priority {
    margin-left: 6px;
    display: inline-block;
    font-size: 11px;
    position: relative;
}

#ticketHistoryTabs {
    .nav-link.active .badge-ticket-type {
        background: #b31e1f;
        color: #fff;
        border-color: #b31e1f;
    }
    .nav-link .badge-ticket-type {
        color: $dark-gray;
        border: 1px solid #999;
    }
    .nav-link:not(.active):hover,
    .nav-link:not(.active):focus,
    .nav-link:not(.active):active {
        border-bottom: 3px solid #ced4da !important;
        transition: all 0.25s ease;
    }
}

.ticket-history {
    pre {
        display: block;
        font-size: 87.5%;
        color: #212529;
        background-color: #f8f9f9;
        border: 1px solid #c2c8cc;
        line-height: 19px;
        padding: 6px 10px;
        border-radius: 3px;
        margin: 15px 0;
    }

    .ticket-comments-wrapper:last-child .ticket-comment {
        border-bottom: none;
    }
    .ticket-comment {
        margin-bottom: 20px;
        border-bottom: 1px solid #ced4da;
    }
    .comment-avatar {
        position: relative;
        top: -3px;
        padding: 0 20px 20px 20px;
    }
    .comment-internal {
        border-radius: 5px;
        padding: 15px;
        background-color: #fcfae8;
        border: 1px solid #ced4da;
    }
}

.popover-ticket {
    background: $dark-gray;
    z-index: 99999;
    width: 100% !important;
    max-width: 500px !important;
    min-width: 300px !important;
}

.tagify-dropdown-custom {
    z-index: 99;
    max-width: 350px;
    max-height: 210px;
    position: absolute;
    top: 113px !important;
    left: 63px !important;

    .empty,
    .preloader {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 53px;
        width: 100%;
        padding: 10px 15px;
    }
}

.addUserToZendesk {
    padding: 12px 15px;
    background: #fff;
    border: 1px solid #ced4da;
    top: -8px;
    position: relative;
    box-shadow: 0 0.25rem 0.425rem rgba(0, 0, 0, 0.065);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.tagify-dropdown-custom .tagify__dropdown__wrapper {
    display: inline-block;
    border: 1px solid #ced4da;
    border-bottom: none;
    max-width: 350px;
    max-height: 210px;
    min-height: 53px;
    width: 350px;
    overflow-y: scroll;
    overflow-x: hidden;
    box-shadow: $box-shadow-lg;
}

.tagify__dropdown {
    border: none;
}

.tagify-dropdown-custom .tagify__dropdown__item {
    border: none;
    padding: 5px 15px;
}

.tagify-dropdown-custom .tagify__dropdown__item--active {
    background: $brand-red;
    color: $white;
}

.tagify-dropdown-custom .tagify__dropdown__item:hover {
    background: $brand-red;
    color: $white;
    border: none;
}

#orderPopover {
    .order-id-wrapper {
        margin-top: -3px !important;
        position: relative;
    }
}

.ticket-form-wrapper {
    @extend .shadow;
}

//------------------------------------
// MACROS DROPDOWN & VIEW MACROS MODAL
//------------------------------------

.macros-dropdown-wrapper {
    margin-left: 390px;
    transition: margin-left 0.275s ease;
}

.has-sidebar-closed .macros-dropdown-wrapper {
    transition: margin-left 0.275s ease;
    margin-left: 90px;
}

.macros-dropdown {
    height: 36px !important;

    .dropdown-toggle:after {
        color: $gray;
    }

    .dropdown-menu {
        z-index: 999;
    }

    .dropdown-menu .dropdown-item {
        color: $gray;
        padding-top: 8px;
        padding-bottom: 8px;
        height: 40px;
        display: flex;
        align-items: center;
        padding-right: 75px;

        &:active,
        &.active,
        &.selected {
            color: $white;
        }

        &.selected:active,
        &.selected:focus {
            color: $white;
        }

        .btn {
            @extend .shadow-lg;
            background: $white !important;
            background-color: $white !important;
            color: $gray;
            margin: 0;
            display: none;
            transition: all 0.25s ease;
            border: 1px solid $border-color;
            position: absolute;
            right: 20px;

            &:hover {
                @extend .shadow-sm;
            }

            &:focus,
            &:active {
                box-shadow: none;
            }
        }

        .fa,
        .fas,
        .fab,
        .far {
            color: $gray !important;
        }

        &:hover {
            color: $gray;

            .btn {
                display: block;
            }
        }
    }

    .filter-option {
        &:before {
            @extend .fa-icon;
            content: "\f0e7";
            color: $gray;
            padding: 0 10px 0 0;
            display: block;
            position: relative;
        }

        .btn {
            display: none;
        }
    }
}

.zd-macro-body {
    position: relative;
    max-height: 58vh !important;
    overflow-y: hidden !important;

    .macro-comment {
        border-radius: 5px;
        padding: 15px;
        min-height: 200px;
        font-size: 13px;
    }

    .macro-public-reply {
        background: #f9f9f9;
        border: 1px solid #dee2e6;
    }

    .macro-internal-note {
        background-color: #fcfae8;
        border: 1px solid #ced4da;
    }

    .macro-tabs {
        @extend .ticket-form-tabs;
    }

    .zd-macro-wrapper {
        max-height: 50vh;
        position: relative;
    }

    .zd-comments-column,
    .zd-settings-column {
        overflow-y: scroll;
        max-height: 50vh;
    }

    .zd-settings-column input,
    .zd-settings-column tags {
        cursor: default;
    }

    .zd-comments-column {
        p {
            margin: 0;
            padding: 0;
        }
    }

    .zd-settings-actions li:last-child {
        padding-bottom: 15px;
    }

    &.ticket-form .tagify__tag {
        margin: 5px 0 5px 5px !important;
        padding: 0px 6px !important;
        cursor: default;
        pointer-events: none;
    }

    .tagify[readonly] .tagify__tag > div::before {
        background: #e5e5e5 !important;
    }

    .tagify__tag > div::before {
        border-radius: 5px !important;
    }
}

.subject-input[contenteditable].has-unapplied-macro-update,
.tagify.has-unapplied-macro-update,
input.has-unapplied-macro-update,
.has-unapplied-macro-update select,
.has-unapplied-macro-update .dropdown-toggle {
    transition: background 0.25s ease;
    background: $light-yellow;
    background-color: $light-yellow;
}

#ticketSubject {
    height: auto;
    padding: 0.7rem 0.75rem;
}

.ticket-sidebar tags {
    font-size: 12px;
    height: auto !important;
    min-height: 37px;
    display: flex;
    align-items: center;
    padding-left: 4px;
    padding-right: 4px;
    width: 100%;
    font-weight: 400;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    .tagify__input {
        min-width: auto;
        display: inline-block;
    }

    &.tagify--focus,
    &:active,
    &:focus {
        color: #495057;
        background-color: #fff;
        border-color: #e66a6b;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(179, 30, 31, 0.25);
    }
}

//---------------------------------
// Ticket Attachments
//---------------------------------

.ticket-attachments-preview,
.ticket-attachments-wrapper {
    .attachment-wrapper {
        height: 150px;
        width: 150px;
        position: relative;
        overflow: hidden;
    }

    .ticket-attachment {
        height: 150px;
        width: 150px;
        width: 100%;
        position: relative;
        border-radius: 5px;
        background: #fff;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid #ced4da;
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
        transition: all 0.3s ease;
    }

    .ticket-attachment + .dz-remove,
    .ticket-attachment:active,
    .ticket-attachment:focus,
    .ticket-attachment:hover {
        // border-color: $brand-red;
        // transition: border-color .25s ease;
        border-color: #cdcdcd;
        opacity: 0.75;
    }

    .ticket-attachment:active .attachment-footer {
        background: #e5e5e5;
        border-color: #cdcdcd;
        transition: background 0.25s ease;
    }

    .ticket-attachments-list {
        margin-left: 88px;
    }

    .ticket-attachments-list li {
        display: inline-block;
        margin-right: 25px;
    }

    .attachment-footer {
        background: #f5f5f5;
        height: 45px;
        width: 100%;
        padding-top: 5px;
        padding-bottom: 5px;
        position: relative;
        display: block;
        border-top: 1px solid #ced4da;
        display: flex;
        flex-direction: column;
        align-items: baseline;
    }

    .ticket-attachment-file {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        overflow: hidden;
        position: relative;
    }

    .ticket-attachment-image {
        display: block;
        height: 100%;
        width: 100%;
        overflow: hidden;
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }

    .attachment-filename {
        padding: 0px;
        font-size: 12px;
    }

    .attachment-extension {
        position: relative;
        margin: -4px 0 0 0;
        padding: 0px;
        color: #888;
        font-size: 13px;
    }
}

//---------------------------
// EDITOR DROPZONE STYLES
//---------------------------

.ticket-form {
    .attachment-footer {
        z-index: 99;
        position: relative;
    }
    .dropzone-hidden {
        min-height: 0px;
        height: 0px;
        width: 0px;
        overflow: hidden;
        padding: 0px;
        border: none;
    }
    .ck-dropzone-preview {
        position: relative;
        z-index: 0;
        max-height: 200px;
        min-height: 0px;
        width: 100%;
        height: 0px;
        display: block;
        border: none;
        padding: 10px 10px 10px 5px;
        overflow: hidden;
        border: none;
        border-left: 1px solid #ced4da;
        border-right: 1px solid #ced4da;
        background: transparent;
    }
    .ck-dropzone-preview.hidden {
        padding: 0px;
    }
    .ck-dz-preview {
        padding: 0px;
        max-width: 150px;
        overflow: hidden;
        position: relative;
        border-radius: 5px;
    }
    .ck-dz-preview .dz-image {
        background: #fff;
        border-radius: 0px;
        max-width: 150px;
        position: relative;
    }
    .dropzone .ck-dz-preview.dz-preview .dz-remove {
        font-size: 14px;
        text-align: center;
        display: block;
        cursor: pointer;
        border: none;
        position: absolute;
        z-index: 99;
        top: 5px;
        right: 5px;
        transition: all 0.25s ease;
    }
    .dropzone .ck-dz-preview.dz-preview .dz-remove:after {
        display: none;
        z-index: 100;
        opacity: 1;
        height: 20px;
        width: 20px;
        color: #000;
        content: "\f00d";
        top: 2px;
        right: 2px;
        padding: 0px;
        margin: 0px;
        font-size: 12px;
        cursor: pointer;
        background: #fff;
        border-radius: 50%;
        position: absolute;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        box-shadow: 0 0.25rem 0.425rem rgba(0, 0, 0, 0.065);
        border: 1px solid #ced4da;
        transition: all 0.25s ease;
    }
    .dz-preview .ticket-attachment:hover > .dz-remove:after,
    .dz-image:hover > .dz-remove:after,
    .dz-details:hover > .dz-remove:after,
    .ck-dz-preview:hover > .dz-remove:after {
        display: block !important;
    }
    .ck-dz-preview {
        position: relative;
        border-radius: 5px;
        background: #fff !important;
        overflow: visible;
        max-height: 150px;
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    }
    .dropzone .dz-preview:hover .dz-image img {
        filter: none !important;
        transform: none !important;
    }
    .dropzone .dz-preview.dz-file-preview .dz-image,
    .dropzone .dz-preview .dz-image {
        background: #fff !important;
        border-radius: 0 !important;
        overflow: hidden;
        width: 100%;
        min-height: 103px;
        position: relative;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .attachment-filename {
        overflow: hidden;
        width: 125px;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .dropzone .dz-preview .dz-image img {
        display: block;
        object-fit: cover;
        width: 100%;
    }
    .ck-dropzone-preview.as-internal {
        background-color: #fcfae8 !important;
        transition: all 0.2s ease;
    }
    .ck-attachments {
        height: 0px;
        width: 0px;
        visibility: hidden;
    }
}

//---------------------------
// MERGE TICKET STYLES
//---------------------------

.merge-ticket {
    background: #f9f9f9;
    border-radius: 5px;
    border: 1px solid #ced4da;
}
.merge-divider {
    border-top: 1px solid #ced4da;
}
.merge-divider .circle {
    margin-top: -24px;
    margin-left: auto;
    margin-right: auto;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 10px solid #f9f9f9;
    font-size: 12px;
    background: #6c757d;
}
.merge-with .name,
.merge-with .ticket-info {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
}
.merge-with .ticket-info {
    color: #2a2a2a;
    font-weight: 700;
}
.merge-with .ticket-link a {
    top: -5px;
    position: relative;
}
.merge-comment {
    margin-top: 5px;
}

.ticket-requester-icon {
    background: url("../images/ticket-requester.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

//---------------------------
// TICKET INDEX ONLY STYLES
//---------------------------

.tickets-index {
    #ticketsTable td {
        transition: background-color 0.25s ease;
        background-color: #fff;
    }
    #ticketsTable .selected-row td {
        transition: background-color 0.25s ease;
        background-color: #fcfae8;
    }
    .circle.xs .rating-letter-na {
        top: -1px !important;
        position: relative;
        font-size: 8px !important;
    }
    .circle.xs .rating-letter {
        font-size: 13px !important;
        position: relative;
    }
    .circle.xs .rating-letter-signed {
        padding-left: 4px !important;
        top: 1px;
        position: relative;
    }
    .no-customer {
        font-size: 13px;
        position: relative;
    }
    .customer-with-rating {
        position: relative;
    }
}
