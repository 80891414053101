#navbarDropdown {
    .dropdown-header {
        padding: 5px;
    }

    .dropdown-item.active {
        border-left: 3px solid $brand-red;
        background: #f5f5f5;
        border-top: 1px solid #ced4da;
        border-bottom: 1px solid #ced4da;
    }

    .circle {
        background: $dark-gray;
    }

    & + .dropdown-menu {
        .menu-icon,
        .fa,
        .fas,
        .far,
        .fab {
            // display: none;
        }
    }
}

.navbar-nav {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-window-name {
    font-size: 16px;
    color: #000;
}

.main-menu-nav {
    .nav-item:not(.dropdown) {
        margin: 0 0 0 5px;
        font-weight: 500;

        .nav-link {
            height: 65px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            border-bottom: 2px solid transparent;
            border-top: 2px solid transparent;

            &:hover,
            &:active,
            &:focus {
                transition: all 0.15s ease;
                // font-weight: 700;
                border-bottom: 2px solid $brand-red;
            }
        }

        &.active .nav-link {
            transition: all 0.15s ease;
            font-weight: 700;
            border-bottom: 2px solid $brand-red;
        }
    }
}

.navbar {
    border-top: 4px solid $brand-red;
    height: $navbar-height;
    justify-content: center;
    align-items: center;
    display: flex;
    transition: none;

    @media (max-width: 767px) {
        transition: height 0.3s ease;
        height: $navbar-height-mobile;
    }

    .main-menu-navbar {
        .nav-item {
            border-bottom: 3px solid transparent;
            height: 100%;
            padding: 0px 5px;
            margin: 3px 2px 0;

            &:active,
            &:focus,
            &:hover,
            &.active {
                outline: 0;
                transition: border-bottom 0.25s ease, color 0.25s ease;
                border-bottom: 3px solid $brand-red;
                height: 100%;
            }

            &.active {
                font-weight: 700;
            }
        }

        .navbar-link {
            height: 100%;
            transition: all 0.25s ease;
        }
    }

    .navbar-brand {
        background: $brand-red;
        width: $sidebar-width;
        height: calc(#{$navbar-height} - 4px);
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9;
        box-shadow: 4px 0 0.25rem rgba(0, 0, 0, 0.125);
        transition: none;

        @media (max-width: 767px) {
            transition: height 0.3s ease, width 0.3s ease;
            height: calc(#{$navbar-height-mobile} - 4px);
            width: 75px;
        }

        img {
            padding-right: 3px;
            transition: none;

            @media (max-width: 767px) {
                transition: 0.3s ease;
                height: auto;
                width: 55px;
            }
        }
    }

    h1 {
        font-size: 16px;
        font-weight: 700;
    }

    .avatar {
        border-radius: 50%;
        height: 40px;
        width: 40px;
        margin: 0px 8px;
        background: $brand-red;
        color: $white;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
    }
}

.nav-icon {
    cursor: pointer;

    &:after {
        display: none;
    }

    &.nav-link {
        color: $gray;
        opacity: 1;
        font-size: 22px;

        &:hover,
        &:active,
        &:focus {
            opacity: 0.75;
            transition: 0.25s ease;
        }
    }

    .nav-icon-pill {
        right: 0px;
        top: 14px;
        font-size: 9px;
        font-weight: normal;
        pointer-events: none;
    }
}

#navbarDropdown {
    cursor: pointer;

    &:after {
        display: none;
    }

    &:hover {
        .avatar,
        .fa-chevron-down {
            opacity: 0.875;
            transition: 0.15s ease;
        }

        .avatar {
            color: #fff;
        }
    }
}

.navbar-expand-md .navbar-collapse {
    padding-left: 20px;
    padding-right: 20px;
    background: #fff;
}

.navbar-toggler {
    border: none;
    outline: 0 !important;
    padding: 0.25rem 0;

    &:focus,
    &:hover,
    &:active {
        color: $dark-gray;
    }
}

.nav-search-wrapper {
    width: 306px;
    min-width: 306px;
    max-width: 306px !important;

    @media (max-width: 767px) {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
    }
}

#subnavSearch {
    padding-right: 35px;

    & + button {
        margin: 0;
        padding: 5px;
        border: 0;
        outline: 0;
        background: none;
        float: right;
        top: 3px;
        position: absolute;
        right: 23px;

        i {
            color: $light-gray;
        }

        &:focus i,
        &:active i,
        &:hover i {
            color: $gray;
            transition: 0.3s ease;
        }
    }

    @media (max-width: 767px) {
        margin-bottom: 15px;
    }
}

.subnav {
    position: fixed;
    z-index: 100;
    background: #f5f5f5;
    border-top: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
    padding: 0px 15px;
    width: calc(100% - #{$sidebar-width});
    // min-height: 64px;
    min-height: 53px;

    @media (max-width: 767px) {
        width: 100%;
        position: relative;
    }

    &.with-shadow {
        transition: box-shadow 0.35s ease;
        box-shadow: $box-shadow;
    }
    &.without-shadow {
        transition: box-shadow 0.35s ease;
        box-shadow: none;
    }
}

.subnav label {
    @media (max-width: 991px) {
        display: none;
    }
}

#subnavDateFilter {
    padding-right: 35px;
    background: $white;

    & + i {
        margin: 0;
        padding: 5px;
        border: 0;
        outline: 0;
        background: none;
        top: 13px;
        position: absolute;
        right: 20px;

        i {
            color: $light-gray;
        }

        &:focus i,
        &:active i,
        &:hover i {
            color: $gray;
            transition: 0.3s ease;
        }
    }
}

.breadcrumbs {
    list-style: none;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    padding: 0;
    font-size: 14px;
    flex-wrap: nowrap;

    .bc-icon {
        margin-right: 4px;
        font-size: 17px;
        transition: color 0.25s ease;
        color: $brand-red;

        &:hover,
        &:active,
        &:focus {
            color: $dark-gray;
        }
    }

    li {
        white-space: nowrap;
        font-weight: 700;
        display: inline-block;
        height: 100%;

        a {
            color: $dark-gray;
            transition: color 0.25s ease;

            &:hover,
            &:focus,
            &:active {
                color: $brand-red;
            }
        }

        &:after {
            font-weight: 400;
            content: "/";
            display: inline-block;
            padding: 0 7px;
        }

        &:first-child,
        &:last-child {
            padding-right: unset;

            &:after {
                display: none;
            }
        }

        .active {
            font-weight: 400;
        }
    }

    @media (max-width: 767px) {
        li {
            display: none;

            &:first-child,
            &.active {
                display: inline-block;
            }
        }
    }

    .bc.active {
        font-weight: 400;
    }
}
