.announcement {
    .title {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid $border-color;
    }

    .description {
        background: $light-yellow;
        border: 1px solid $border-color;
        padding: 20px;
        border-radius: 3px;
    }
}

.trix-content {
    min-height: 35vh;

    & + div {
        @extend .form-control;
    }
}

.trix-disabled.trix-content {
    min-height: auto;
}
