.card {
    border: 1px solid $border-color;
}

.overview-quick-stats {
    .card-body {
        padding: 0.735rem 0.975rem 0.735rem 0.735rem !important;
    }

    @media (max-width: 991px) {
        padding: 10px;
    }
    @media (max-width: 767px) {
        display: none;
    }
}

.breakdown-card {
    height: 640px;

    .breakdown-chart {
        height: 320px;
        margin-top: 10px;
    }

    .breakdown-table {
        height: 287px;
        position: relative;
        overflow: hidden;
    }

    .breakdown-empty {
        display: block;
        min-height: 638px;
    }
}

.card-header {
    border-bottom: 1px solid $border-color;
}

.card-footer {
    border-top: 1px solid $border-color;
}

.breakdown-card-body {
    display: block;
    min-height: 350px;
    border-bottom: 1px solid $border-color;
}

.card-body {
    input {
        background: #fff;
    }
}

.card-header .bootstrap-select {
    margin-right: -2px;
    margin-top: -1px;
}

.card-footer {
    input {
        background: #fff;
    }
}

a.card {
    color: inherit;
    text-decoration: none;
    transition: all 0.2s ease;

    &:hover {
        cursor: pointer;
        text-decoration: none;
        position: relative;
        box-shadow: $box-shadow-lg;
        transition: all 0.2s ease;
    }

    &:active {
        transition: all 0.15s ease;
        cursor: pointer;
        text-decoration: none;
        position: relative;
        box-shadow: $box-shadow;
    }
}

.card-footer,
.card-header {
    background: #f9f9f9;
    color: $dark-gray;
}

.card-header {
    padding-top: 14px;
    padding-bottom: 12px;
    font-size: 15px;
    font-weight: 600;
    display: flex;
    align-items: center;
    flex-direction: row;
    max-height: 51px;

    .bootstrap-select .dropdown-toggle {
        padding-top: 10px;
        padding-bottom: 8px;
    }

    span {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .fas:not(.reg):not(.help-icon),
    .far:not(.reg):not(.help-icon),
    .fab:not(.reg):not(.help-icon),
    .fa:not(.reg):not(.help-icon) {
        color: $brand-red;
        margin-right: 13px !important;
        font-size: 18px;
        position: relative;
        top: -1px;
    }
}

.card-body {
    position: relative;
    overflow: hidden;
}

.card {
    overflow: hidden;
    margin: 0px 0px 30px 0;
    box-shadow: $box-shadow;
}
