.settings-sidebar {
    .filter-menu-parent:last-child {
        .collapse {
            border-bottom: none !important;
        }
    }

    .filter-item a {
        font-size: 14px !important;
        color: #555;
    }
    // .filter-item a.active {
    //     color: #b31e1f;
    // }
    .filter-item a.active:before {
        display: block;
        color: #b31e1f;
        content: "\f105";
        font-family: "Font Awesome 5 Free";
        font-style: normal;
        font-weight: 900;
        text-decoration: inherit;
        font-size: 14px;
        transition: all 0.2s ease;

        padding-right: 5px;
        display: inline-block;
        position: relative;
    }
    .filter-item a:hover {
        color: #b31e1f;
        transition: all 0.2s ease;
    }
}

.cache-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
