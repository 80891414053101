.circle {
    display: block;
    box-sizing: border-box;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    background: #999;

    &.tiny {
        height: 11px;
        width: 11px;
    }

    &.xs {
        height: 18px;
        width: 18px;
    }

    &.sm {
        height: 24px;
        width: 24px;
    }

    &.md {
        height: 36px;
        width: 36px;
    }

    &.lg {
        height: 48px;
        width: 48px;
    }

    &.xl {
        height: 72px;
        width: 72px;
    }

    &.circle-border {
        border: 1px solid transparent;
    }

    &.circle-shadow {
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    }
}

.circle-hover {
    height: 35px;
    width: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 500px;
    transition: all 0.2s ease;

    &:hover {
        background: #f0f0f0;
    }
}

.rating-circle {
    position: relative;
    border: 1px solid #ced4da;
    box-shadow: inset $box-shadow-lg;

    span {
        font-size: 16px;
        color: #fff;
        position: relative;
        z-index: 3;
    }
}

.circle.sm.rating-letter-na {
    font-size: 10px !important;
    top: -1px !important;
}

.circle.sm .rating-letter-signed {
    padding-left: 4px !important;
}

.circle.sm .rating-letter {
    padding-left: 1px;
}

.circle-shadow-sm,
.circle-shadow {
    box-shadow: 0 0.125rem 0.325rem rgba(0, 0, 0, 0.1) !important;
}
.circle-shadow-md {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15) !important;
}
.circle-shadow-lg {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2) !important;
}

.circle.lg .fa-exclamation-triangle {
    font-size: 25px;
    top: -2px;
    position: relative;
}

.circle.sm .fa-exclamation-triangle {
    font-size: 12px;
}
