body,
html {
    font-family: "Roboto", sans-serif;
    background: rgba(249, 249, 249, 0.15);
    background-color: rgba(249, 249, 249, 0.15);
    height: 100%;
    width: 100%;
    max-width: 100%;
}

body {
    padding-top: $navbar-height;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;

    @media (max-width: 767px) {
        padding-top: $navbar-height-mobile;
    }
}

main {
    position: relative;
    overflow: hidden;
    top: 66px;

    @media (max-width: 767px) {
        top: 10px;
    }
}

a:focus,
a:active,
a:hover {
    color: $brand-red;
}

hr {
    border: 0;
    border-top: 1px solid #ced4da;
    opacity: 0.5;
}

.avatar-wrapper {
    display: flex;
    align-items: center;

    // DEFAULT
    .circle {
        font-weight: 700;
        margin-right: 8px;
        font-size: 17px;
        line-height: 17px;
    }

    .avatar {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .name {
        position: relative;
        top: 1px;
        font-weight: 700;
        padding: 0;
        margin: 0;
        line-height: 17px;
    }

    .role {
        padding: 0;
        margin: 0;
        line-height: normal;
        font-size: 12px;
        font-weight: 400;
    }

    // TODO: LG / MD / XS

    // SMALL
    &.sm {
        .circle {
            margin-right: 5px;
            font-size: 13px;
            line-height: 13px;
        }

        .role {
            display: none;
        }

        .name {
            font-size: 14px;
            line-height: 15px;
        }
    }
}

.preloader-overlay {
    z-index: 10;
    background: rgba(255, 255, 255, 0.75);
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    opacity: 1;
}

button[disabled] {
    cursor: not-allowed;
}

.animated {
    visibility: visible !important;
}

.hover-gray:hover {
    transition: color 0.25s ease;
    color: $dark-gray;
}

.clickable.hover-red {
    a {
        color: $dark-gray;
    }

    &:hover {
        transition: color 0.25s ease;
        color: $brand-red !important;

        .fa,
        .far,
        .fas,
        .fab {
            transition: color 0.25s ease;
            color: $brand-red !important;
        }

        a {
            transition: color 0.25s ease;
            color: $brand-red !important;
        }
    }
}

.bootstrap-select.disabled.bg-white {
    background: #fff !important;
    background-color: #fff;
    cursor: not-allowed;
    box-shadow: none !important;

    .dropdown-toggle {
        color: #495057 !important;
        border: 1px solid #ced4da !important;
        opacity: 1 !important;
        pointer-events: none;
    }
}

.hover-red:hover {
    color: $brand-red;
    transition: color 0.25s ease;
}

.ff-flag-icon {
    position: relative;
    top: -2px;
    border: 1px solid #ced4da;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.125);
}

.shadow {
    box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.03) !important;
}

#ticketSubject {
    box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.03);
}

.form-control:focus,
.daterangepicker select.minuteselect:focus,
.daterangepicker select.hourselect:focus,
.daterangepicker select.ampmselect:focus,
.hover-input:focus {
    color: #495057;
    background-color: #fff;
    border-color: #e66a6b;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(179, 30, 31, 0.25) !important;
}

[data-ax-clipboard] {
    @extend .clickable;
    transition: all 0.25s ease;
    &:active,
    &:focus,
    &:hover {
        color: $brand-red;
    }
}

.toggle-row td {
    transition: background 0.3s ease, color 0.3s ease;

    .fa {
        transition: opacity 0.3s ease;
    }
}

.toggle-row-disabled td {
    transition: background 0.3s ease, color 0.3s ease;
    background: rgba(249, 249, 249, 0.75);

    &:not(.actions-col) {
        color: rgba(0, 0, 0, 0.35);

        .fa {
            opacity: 0.35;
        }

        .black,
        .gray {
            color: rgba(0, 0, 0, 0.35) !important;
        }
    }
}

.portal-avatar {
    border: 1px solid rgba(0, 0, 0, 0.125);
    height: 110px;
    width: 110px;
    box-shadow: $box-shadow-lg;
    transition: all 0.25s ease;

    &:active {
        box-shadow: $box-shadow;
    }
}

.fa-icon {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}

#toast-container {
    & > div {
        box-shadow: $box-shadow-lg;
    }

    & > div:hover {
        box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
        transition: all 0.25s ease;
    }

    .toast {
        opacity: 1;
        max-width: 400px !important;
        width: 400px !important;
        padding: 10px 15px 10px 20px;
        border-radius: 5px !important;
        background-image: none !important;
        background-color: #2a2a2a;
        display: flex;
        align-items: center;

        &:before {
            @extend .fa-icon;
            margin-right: 17px;
            font-size: 24px;
        }
    }

    .toast-progress {
        background-color: #999;
    }

    .toast-success {
        border-left: 5px solid $green;

        &:before {
            @extend .fa-icon;
            color: $green;
            content: "\f560";
        }
    }

    .toast-error {
        border-left: 5px solid $brand-red;

        &:before {
            @extend .fa-icon;
            color: $brand-red;
            content: "\f071";
        }
    }
}

label {
    font-weight: 500;
    font-size: 14px !important;
    color: $dark-gray;
}

.alert {
    @extend .shadow;
    border-color: #ced4da;
}

.alert-danger {
    // background:
    // background-color: rgba(179, 30, 31, 0.125);

    .fa,
    .fas,
    .far,
    .fab {
        color: $brand-red;
    }
}

.alert-warning {
    background: $light-yellow;
    background-color: $light-yellow;

    .fa,
    .fas,
    .far,
    .fab {
        color: $dark-yellow;
    }
}

.alert-info {
    .fa,
    .fas,
    .far,
    .fab {
        color: #385d7a;
    }
}

.alert-secondary {
    background: #f9f9f9;
}

.alert-success {
    border-left: 5px solid $green;
    .fa,
    .fas,
    .far,
    .fab {
        color: $green;
    }
}

.alert-secondary {
    border-left: 5px solid $gray;
    .fa,
    .fas,
    .far,
    .fab {
        color: $gray;
    }
}

.alert-info {
    border-left: 5px solid $blue;
    .fa,
    .fas,
    .far,
    .fab {
        color: $blue;
    }
}

.alert-danger {
    border-left: 5px solid $brand-red;
    .fa,
    .fas,
    .far,
    .fab {
        color: $brand-red;
    }
}

.alert-warning {
    border-left: 5px solid $orange;
    .fa,
    .fas,
    .far,
    .fab {
        color: $orange;
    }
}

.alert-slate {
    border-left: 5px solid $slate;
    .fa,
    .fas,
    .far,
    .fab {
        color: $slate;
    }
}

.alert-slate,
.alert-secondary,
.alert-success,
.alert-info,
.alert-danger,
.alert-warning {
    background: #fff;
    background-color: #fff;
    color: $dark-gray;

    .badge:not(.badge-light) {
        .fa,
        .fas,
        .far,
        .fab {
            color: $white;
        }
    }
}

.badge {
    cursor: default;
    border: none;
    font-weight: normal;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:not(.badge-pill):not(.badge-status) {
        letter-spacing: 0.125pt;
        font-size: 11px;
        line-height: 13px;
        padding: 3px 7px;
        height: 19px;
        display: inline-block;
        // border: 1px solid $border-color;

        .fa,
        .far,
        .fas,
        .fab {
            line-height: 11px !important;
            font-size: 10px !important;
            padding-right: 5px !important;
            margin-right: 0px !important;
        }
    }

    &.badge-uppercase {
        text-transform: uppercase;
    }

    &.badge-wide {
        min-width: 80px;
    }

    &.badge-light {
        color: #2a2a2a !important;
        border: 1px solid #ced4da;
    }

    // &.badge-warning {
    //     border-color: #e29016
    // }

    // &.badge-success {
    //     border-color: #226b22;
    // }

    // &.badge-danger {
    //     border-color: #a11c1d;
    // }

    // &.badge-info {
    //     border-color: $slate;
    // }
}

.light-red-bg {
    background: #f0d2d2;
    background-color: #f0d2d2;
}

.alert-icon {
    font-size: 20px;
}

.no-shadow {
    box-shadow: none !important;
}

.mdi.mdi-check {
    display: inline-block;
    background-image: url("~/images/mdi-check.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(1em + 2px);
    width: calc(1em + 2px);
    top: calc((0% - (100% - 1em)) - 8%);
}

.pretty.p-smooth input:checked + .state .icon,
.pretty.p-smooth input:checked + .state .svg,
.pretty.p-smooth input:checked + .state img {
    animation: none !important;
    -webkit-animation: none !important;
}

#app {
    position: relative;
    height: 100%;
    width: 100%;
}

.main-wrapper {
    overflow: hidden;
    float: right;
    position: relative;
    width: calc(100% - #{$sidebar-width});

    @media (max-width: 767px) {
        transition: margin-left 0.3s ease;
        width: 100%;
    }
}

.guest .main-wrapper {
    width: 100%;
}

.copyright {
    text-align: right;
    margin: 15px 0;
    color: #999;
    font-size: 10px;

    @media (max-width: 991px) {
        text-align: center;
    }
}

.icon {
    display: inline-flex;
    height: 18px;
    width: 24px;
    align-self: center;
    justify-self: center;
}

.fa.fa-question-circle.help-icon {
    font-size: 13px !important;
    color: #666;
    cursor: help;

    &:hover,
    &:active,
    &:focus {
        transition: all 0.25s ease;
        color: $orange;
    }
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #f9f9f9;
    opacity: 1;
    cursor: not-allowed;
}

.form-check-label {
    font-size: 14px !important;
}

.invalid-feedback {
    font-size: 11px;
    strong {
        font-weight: normal;
    }
}

.pagination {
    li {
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    }

    a {
        color: $gray;
    }
}

.page-item.disabled {
    cursor: not-allowed;
}

.badge-order-status,
.badge-info,
.badge-warning,
.badge-danger,
.badge-success {
    &:not(.badge-pill) {
        // box-shadow: 0 0.125rem 0.2rem rgba(0, 0, 0, 0.1);
    }
}

.badge-info {
    background-color: $blue !important;
}

.daterangepicker {
    color: #666;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    box-shadow: 0 0.125rem 0.75rem rgba(0, 0, 0, 0.075);

    .drp-selected {
        top: 2px;
        position: relative;
    }

    .drp-buttons .btn {
        font-weight: normal;
    }

    td.in-range {
        background-color: #f5f5f5;
    }

    td.active,
    .ranges li {
        &.active {
            background-color: $brand-red;
            color: $white;
        }

        &:focus,
        &:hover {
            color: $gray;
            background-color: $hover-color;
        }
    }
}

.core-search-modal ul,
.core-search-modal li {
    list-style: none;
}
.core-search-modal {
    transition: all 0.25s ease;
}

.cache-btn.min-width {
    min-width: 180px;
}
.card-body-border-bottom {
    border-bottom: 1px solid #ced4da !important;
}
