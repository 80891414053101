@media (min-width: 992px) {
    .dd-animate {
        animation-duration: 0.3s;
        -webkit-animation-duration: 0.3s;
        animation-fill-mode: both;
        -webkit-animation-fill-mode: both;
    }
}

@keyframes ddSlideIn {
    0% {
        transform: translateY(1rem);
        opacity: 0;
    }
    100% {
        transform: translateY(0rem);
        opacity: 1;
    }
    0% {
        transform: translateY(1rem);
        opacity: 0;
    }
}

@-webkit-keyframes ddSlideIn {
    0% {
        -webkit-transform: transform;
        -webkit-opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        -webkit-opacity: 1;
    }
    0% {
        -webkit-transform: translateY(1rem);
        -webkit-opacity: 0;
    }
}

@keyframes shadowPulse {
    0% {
        // box-shadow: 0px 0px 15px 0px rgba(252, 250, 232, 1);
        box-shadow: 0px 0px 15px 0px rgba(179, 30, 31, 1);
    }

    100% {
        // box-shadow: 0px 0px 5px 0px rgba(252, 250, 232, 0);
        box-shadow: 0px 0px 5px 0px rgba(179, 30, 31, 0);
    }
}

.shadow-pulse {
    animation-name: shadowPulse;
    animation-duration: 0.75s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
}

.hover-zoom:hover {
    transition: transform 0.25s ease;
    transform: scale(1.01);
}

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: unset;
    animation-fill-mode: unset;
}

.dd-slideIn {
    -webkit-animation-name: ddSlideIn;
    animation-name: ddSlideIn;
}

.dropdown-menu.slide {
    display: block;
    visibility: hidden;
    opacity: 0;
    transform: translateY(1rem);
    transition: all 0.3s ease;
}

.dropdown-menu.slide.show {
    display: block;
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
    transition: all 0.3s ease;
}
