.datatable-wrapper {
    display: none;
}

.datatable-drawer,
.datatable-drawer td {
    background-color: $light-yellow;
    border-color: #f1f1f1;
}

.dataTables_wrapper {
    .row,
    .col-sm-12 {
        margin: 0;
        padding: 0;
    }
}

// Highlight sorted table column.
.highlight-sorted-row th[scope="row"].sorting_1,
.highlight-sorted-row td.sorting_1 {
    border-left: 1px solid #ced4da;
    border-right: 1px solid #ced4da;
    background: $light-yellow !important;
    background-color: $light-yellow !important;
    // background-color: rgba(255, 248, 220, 0.1) !important;
    // background: rgba(255, 248, 220, 0.1) !important;
    transition: all 0.2s ease;

    &:first-child {
        border-left: 1px solid transparent;
    }

    &:last-child {
        border-right: 1px solid transparent;
    }
}

.dataTables_scrollBody {
    overflow: hidden !important;
}

.dataTables_processing {
    box-shadow: none !important;
    border: none !important;
    background: #fff;
    margin-top: 0px !important;
}

div.dataTables_wrapper div.dataTables_processing {
    position: absolute;
    top: 0;
    left: unset;
    text-align: center;
    background: #fff;
    display: flex;
    height: 100%;
    width: 100%;
    margin: 0 !important;
    z-index: 1;
}

.dataTables_scrollHead {
    z-index: 2;
}

.dataTables_scrollBody {
    min-height: 540px;
    position: relative;
}

.support-card .dataTables_scrollBody {
    min-height: auto;
}

.dataTables_scrollHeadInner {
    background: #f9f9f9;
    border-bottom: 1px solid #ced4da;
}

.card-body .dataTables_info,
.card-body .pagination,
.card-body .dataTables_filter label {
    display: none;
}

.dataTables_scroll {
    height: 100%;
}

.dataTables_filter label {
    display: flex;
    align-self: center;
    justify-self: center;
    width: 180px;
    padding: 0;
    margin: 0;
}

.dataTables_empty {
    padding-top: 250px !important;
    width: 100%;
    background: #fff;
    text-align: center;
    font-weight: bold;
}

.support-card .dataTables_empty {
    padding: 20px !important;
    width: 100%;
    background: #fff;
    text-align: center;
    font-weight: bold;
}

.dataTables_info {
    font-size: 12px;
    width: 100%;
    text-align: right;
    padding: 2px 10px 0;
}

.card-footer .pagination {
    float: right;
    margin: 0;

    li:not(.next):not(.previous) {
        display: none;
    }

    li.previous.disabled,
    li.next.disabled {
        cursor: not-allowed;
        opacity: 0.8;
    }

    li.next a {
        height: 33px;
        text-indent: -9999px;
        line-height: 0;

        &:after {
            padding: 8px inherit;
            text-indent: 0;
            display: block;
            line-height: initial;
            content: "\f061";
            font-family: "Font Awesome 5 Free";
            font-style: normal;
            font-weight: 900;
            text-decoration: inherit;
            font-size: 14px;
            transition: all 0.2s ease;
        }
    }

    li.previous a {
        height: 33px;
        text-indent: -9999px;
        line-height: 0;

        &:after {
            padding: 8px inherit;
            text-indent: 0;
            display: block;
            line-height: initial;
            content: "\f060";
            font-family: "Font Awesome 5 Free";
            font-style: normal;
            font-weight: 900;
            text-decoration: inherit;
            font-size: 14px;
            transition: all 0.2s ease;
        }
    }
}

table.dataTable thead > tr > th {
    outline: 0;
}

table.dataTable thead > tr > th.sorting_asc.text-center:not(.no-sort),
table.dataTable thead > tr > th.sorting_desc.text-center:not(.no-sort),
table.dataTable thead > tr > th.sorting.text-center:not(.no-sort),
table.dataTable thead > tr > td.sorting_asc.text-center:not(.no-sort),
table.dataTable thead > tr > td.sorting_desc.text-center:not(.no-sort),
table.dataTable thead > tr > td.sorting.text-center:not(.no-sort) {
    padding-right: 30px !important;
    padding-left: 30px !important;
}

.no-sort {
    cursor: default !important;
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;

    &:before,
    &:after {
        display: none !important;
    }
}

.dataTables_scrollHead,
.dataTables_scrollHeadInner {
    width: 100% !important;
}

.dataTables_scrollHeadInner + table,
.dataTables_scrollHeadInner thead {
    width: auto !important;
}

table.dataTable.table-hover > tbody > tr:hover > *,
table.dataTable.table-striped > tbody > tr.odd > * {
    box-shadow: unset;
}
